import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateNowPlaying, updateIsPaused, updateIsPlaying, updatePlayerPosition, setPlayerStatus } from '../FlikTraxStore/Actions/audioPlayerActions'
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import SettingsIcon from '@mui/icons-material/SettingsRounded';
import PlayCircleOutline from '@mui/icons-material/PlayCircleOutlineRounded';
import PauseCircleOutline from '@mui/icons-material/PauseCircleOutlineRounded';
import StopCircleOutlined from '@mui/icons-material/StopCircleOutlined';
import FastRewind from '@mui/icons-material/FastRewindRounded';
import Forward10 from '@mui/icons-material/Forward10Rounded';
import Replay10 from '@mui/icons-material/Replay10Rounded';
import IconButton from '@mui/material/IconButton';
import ZoomIn from '@mui/icons-material/ZoomInRounded';
import VolumeOff from '@mui/icons-material/VolumeOffRounded';
import VolumeUp from '@mui/icons-material/VolumeUpRounded';
import ZoomOut from '@mui/icons-material/ZoomOutRounded';
import ReactWaves from '@dschoon/react-waves';
import Slider from '@mui/material/Slider';
import Collapse from '@mui/material/Collapse';

var truncate = require('truncate');

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(.5),
  textAlign: 'center',
  background: '#38394d',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
}));

class AudioPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wavesurfer: null,
      playing: false,
      attached: false,
      paused: false,
      pos: 0,
      zoom: 0,
      volume: .2,
      tracktitle: null,
      trackartist: null,
      tracksource: null,
      settingsEl: false,
      autoplayTracks: {}
    };
  }

  handleSettingsClick = (event) => {
    this.setState({ settingsEl: event.currentTarget });
  };

  handleSettingsClose = () => {
    this.setState({ settingsEl: null });
  }

  listenTrackDone = () => {

    let autoPlayTracks = this.props.audioplayer.autoplaytracks;
    let currentTrackId = this.props.audioplayer.currentTrackId;

    let currentKey = null;
    autoPlayTracks.map((track, index) => {
      if (track.id === currentTrackId) {
        currentKey = index;
      }
    });

    let nextKey = currentKey + 1;

    let nextTrack = autoPlayTracks[nextKey];

    let ds = {
      'source': nextTrack.preview_url,
      'tracktitle': nextTrack.track_title,
      'artist': nextTrack.artist_name,
      'description': nextTrack.description,
      'duration': nextTrack.duration,
      'vocaltype': nextTrack.vocal_type,
      'trackid': nextTrack.id,
      'filecompressed': nextTrack.file_path_compressed,
      'fileuncompressed': nextTrack.file_path_uncompressed
    };
    this.props.updateNowPlaying(ds);
    this.props.updateIsPlaying(true);
    this.props.updatePlayerPosition(0);
  }

  formatTrackCounter(time) {
    var minutes = Math.floor(time / 60).toString().padStart(2, '0');
    var seconds = Math.floor(time - minutes * 60).toString().padStart(2, '0');
    if (!isNaN(minutes)) {
      return minutes + ':' + seconds;
    } else {
      return '00:00';
    }
  }

  getTrackCounterUpdate() {

    if (this.props.audioplayer.isPlaying) {
      if (this.state.wavesurfer !== null) {
        return this.formatTrackCounter(this.props.audioplayer.position);
      }
    } else {
      return '00:00';
    }
  }

  getTrackDuration() {
    if (this.state.wavesurfer !== null) {
      return this.formatTrackCounter(this.state.wavesurfer.getDuration());
    } else {
      return '00:00';
    }
  }

  onPosChange = (pos, wavesurfer) => {

    var currentDuration = wavesurfer.getDuration();
    var autoPlayEnabled = this.props.audioplayer.autoplay;

    if (this.props.audioplayer.position === Math.floor(currentDuration) && autoPlayEnabled) {
      this.props.updateIsPlaying(false);
      this.listenTrackDone(wavesurfer);
    }
    if (pos !== this.props.audioplayer.position) {
      this.setState({ pos, wavesurfer });
      this.props.updatePlayerPosition(pos);
    }
  };

  stopPlaying = () => {
    this.state.wavesurfer.stop();
    this.props.updateIsPaused(false);
    this.props.updateIsPlaying(false);
  }

  skipAhead = () => {
    this.state.wavesurfer.seekTo(this.secondsToPosition(this.state.pos + 10));
  };

  secondsToPosition = (sec) => {
    return 1 / this.state.wavesurfer.getDuration() * sec;
  };

  handleSliderChange = (event, newValue) => {
    this.setState({ zoom: newValue })
  };

  renderZoom = () => {
    var currentZoom = this.state.zoom;
    if (currentZoom > 0) {
      return (
        <ZoomOut fontSize='large' style={{ marginLeft: 10, marginRight: 15 }} />
      );
    } else {
      return (
        <ZoomIn fontSize='large' style={{ marginLeft: 10, marginRight: 15 }} />
      );
    }
  }

  handleVolumeChange = (event, newVolume) => {
    this.setState({ volume: newVolume });
  };



  render() {

    const open = Boolean(this.state.settingsEl);
    const id = open ? 'settings-popover' : undefined;
    const settingBtn = {
      bgColor: {
        float: 'left',
        backgroundColor: 'white'
      }
    }

    return (
      <Collapse in={((this.props.audioplayer.isPlaying) || (this.props.audioplayer.isPaused))} >

        <Box sx={{ flexGrow: 1 }} id="ftaudioplayer" className='audiowrapper'>
          <Grid container spacing={0} style={{ border: 0 }}>
            <Grid item xs>
              <Item>

                <div className='audio-controls-column'>
                  <div className="audiobtn-wrapper">
                    <Link to="#" onClick={() => { this.state.wavesurfer.seekTo(0) }}></Link>
                    <Link to="#" onClick={() => { this.state.wavesurfer.seekTo(0) }}><FastRewind fontSize='large' /></Link>
                    <Link to="#" onClick={() => { this.state.wavesurfer.skipBackward(10) }}><Replay10 fontSize='large' /> </Link>

                    {this.props.audioplayer.isPaused && (
                      <Link to="#" onClick={() => { this.stopPlaying() }}><StopCircleOutlined style={{ marginRight: 5 }} fontSize='large' /></Link>
                    )}
                    <Link to="#" id="audioplaybtn" onClick={() => { this.props.updateIsPlaying(!this.props.audioplayer.isPlaying) }}>
                        {!this.props.audioplayer.isPlaying ? <PlayCircleOutline fontSize='large' /> : <PauseCircleOutline fontSize='large' />}
                    </Link>
                    <Link to="#" onClick={() => { this.state.wavesurfer.skipForward(10) }}><Forward10 fontSize='large' /></Link>
                  </div>
                </div>

              </Item>
            </Grid>
            <Grid item xs={8} display={{ xs: "none", md: "block" }}>

              <div className='player-column'>

                <Grid container spacing={3} style={{ height: 43, margin: 'none', padding: 'none' }}>
                  <Grid item xs>
                    <Item style={{color:"white"}}>{this.getTrackCounterUpdate()}</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item><Link to="/search">{truncate(this.props.audioplayer.currentTrackTitle, 30) + ' - ' + this.props.audioplayer.currentArtist} </Link></Item>
                  </Grid>
                  <Grid item xs>
                    <Item style={{color:"white"}}>{this.getTrackDuration()}</Item>
                  </Grid>
                </Grid>

                <ReactWaves
                  ref={this.waveplayer}
                  audioFile={this.props.audioplayer.currentTrack}
                  className='react-waves'
                  //onWaveformReady={console.log('waveform ready')}
                  onPause={() => this.props.updateIsPaused(true)}
                  options={{
                    backend: 'MediaElement',
                    barHeight: 1,
                    barGap: 1,
                    barWidth: 0,
                    cursorWidth: 2,
                    height: 50,
                    hideScrollbar: true,
                    progressColor: '#ff4370',
                    responsive: true,
                    waveColor: 'grey',
                    fillParent: true,
                    autoCenter: true,
                  }}
                  volume={this.state.volume}
                  zoom={this.state.zoom}
                  playing={this.props.audioplayer.isPlaying}
                  pos={this.props.audioplayer.position}
                  onPosChange={this.onPosChange}
                />
              </div>



            </Grid>
            <Grid item xs display={{ xs: "none", md: "block" }}>
              <Item>

                <div className="zoom-column" >
                  <Tooltip title="Waveform Zoom/ Volume">
                    <IconButton style={settingBtn.bgColor} onClick={this.handleSettingsClick} >
                      <SettingsIcon style={{ cursor: 'pointer' }} />
                    </IconButton>
                  </Tooltip>

                  <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.settingsEl}
                    onClose={this.handleSettingsClose}
                    anchorOrigin={{
                      vertical: 'left',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    PaperProps={{
                      style: {
                        width: '30%',
                        // backgroundColor: '#00031e',
                        padding: 25,
                        borderColor: 'white',
                        borderWidth: 1,
                        borderStyle: 'solid'
                      },
                    }}
                    transition disablePortal
                  >
                    <div style={{ padding: 5, margin: 'none', textAlign: 'left', whiteSpace: 'nowrap' }}>
                      {/* <h5 style={{ textAlign: 'center' }}>Waveform Zoom &amp; Volume</h5> */}
                      {this.renderZoom()}
                      <Slider min={0} max={1000} style={{ width: '65%' }} color="secondary" value={this.state.zoom} onChange={this.handleSliderChange} aria-labelledby="zoom" /><br />
                      {this.state.volume > 0 ? <VolumeUp fontSize='large' style={{ marginLeft: 10, marginRight: 15 }} /> : <VolumeOff fontSize='large' style={{ marginLeft: 10, marginRight: 15 }} />}
                      <Slider min={0} max={1} step={.1} style={{ width: '60%', padding: 10 }} color="secondary" value={this.state.volume} onChange={this.handleVolumeChange.bind(this)} aria-labelledby="volume" />
                    </div>
                  </Popover>
                </div>


              </Item>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    )
  }
}

AudioPlayer.propTypes = {
  updateNowPlaying: PropTypes.func.isRequired,
  updateIsPlaying: PropTypes.func.isRequired,
  updatedIsPaused: PropTypes.func.isRequired,
  updatePlayerPosition: PropTypes.func.isRequired,
  setPlayerStatus: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  audioplayer: state.audioplayer,
  api: state.api,
})

export default connect(mapStateToProps,
  {
    updateNowPlaying,
    updateIsPlaying,
    updateIsPaused,
    updatePlayerPosition,
    setPlayerStatus
  })(AudioPlayer);