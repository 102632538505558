import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import drawerLinks from '../../config/drawer-routes';
import MasterPortal from '../../components/MasterPortal';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import SearchElements from './NavigationElements/SearchField';
import PlaylistIcons from './NavigationElements/PlaylistIcons';
import FeaturedCollection from './NavigationElements/FeaturedCollection';
import LibraryMusicOutlinedIcon from '@mui/icons-material/LibraryMusicOutlined';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSignInAlt, faUserPlus, faHome, faSearch, faStream, faSitemap, faAddressCard, faGuitar, faBullhorn, faQuestionCircle, faBell, faCog, faCube  } from '@fortawesome/free-solid-svg-icons';

library.add(faSignInAlt, faUserPlus, faHome, faSearch, faStream, faSitemap, faAddressCard, faGuitar, faBullhorn, faQuestionCircle, faBell, faCog, faCube)

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 2px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function Navigation(props) {

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {

    if (isSmallScreen) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  }, [isSmallScreen]);


  const renderAuthLinks = () => {
    if (props.user && !props.user.isAuthenticated === true) {
      return (
        <React.Fragment>
          <Divider />
          <List></List>
          <Tooltip enterDelay={open === false ? 200 : 60000} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} title="Login" placement="right" arrow>
            <Link to="/login" >
              <ListItem button key={30}>
                <ListItemIcon>
                  <FontAwesomeIcon style={{ color: 'white', marginLeft: 10 }} icon={faSignInAlt} />
                </ListItemIcon>
                <ListItemText primary="LOGIN" />
              </ListItem>
            </Link>
          </Tooltip>
          <Tooltip enterDelay={open === false ? 200 : 60000} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} title="Register" placement="right" arrow>
            <Link to="/register" >
              <ListItem button key={30}>
                <ListItemIcon>
                  <FontAwesomeIcon style={{ color: 'white', marginLeft: 10 }} icon={faUserPlus} />
                </ListItemIcon>
                <ListItemText primary="REGISTER" />
              </ListItem>
            </Link>
          </Tooltip>
          <List></List>
        </React.Fragment>
      )
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar style={{backgroundColor:'#38394d'}} position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {!open && (
            <a href="/" alt="ReelSync Music">
              <Box component="div">
                <img style={{ marginTop: 10, marginRight: 25, maxWidth:125 }} src="/images/logos/reelsync-logo-wb.jpg" alt="ReelSync Music" />
              </Box>
            </a>
          )}
          <SearchElements />
          <PlaylistIcons />
        </Toolbar>

      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader style={{ justifyContent:"center"}} >
        {open && (
          <a href="/" alt="ReelSync Music">
            <Box component="div"> <img style={{ justifyContent:"flex-start", marginTop: 75,  marginLeft: 15, marginBottom: 15, maxWidth:125}} src="/images/logos/reelsync-logo-wb.jpg" alt="ReelSync Music" />
            </Box>
          </a>
        )}
          <IconButton  onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon style={{color:'white'}} /> : <ChevronLeftIcon style={{color:'white'}} />}
          </IconButton>
        </DrawerHeader>
        <List></List>
        <List></List>
        <List></List>
        {open && (
          <>
            <List>
              <Divider />
            </List>
            <List>
              <FeaturedCollection drawerOpen={open} />
            </List>
            {/* <List></List> */}
          </>
        )}
        <Divider />
        <List></List>
        {props.user && props.user.isAuthenticated && (
        <List>
          {Object.values(drawerLinks.navigation).map((data, key) => (
            <Tooltip enterDelay={open === false ? 200 : 60000}
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={Object.values(data)[0]}
              placement="right"
              arrow
            >
              {Object.values(data)[0] === 'SEARCH' ? (
              <a href={Object.values(data)[1]}>
                <ListItem button key={key}>
                  <ListItemIcon>
                    <FontAwesomeIcon style={{ color: 'white', marginLeft: 10 }} icon={faSearch} />
                  </ListItemIcon>
                  <ListItemText primary={Object.values(data)[0]} />
                </ListItem>
              </a>
              ) : (
                <Link to={Object.values(data)[1]}>
                <ListItem button key={key}>
                  <ListItemIcon>
                    {Object.values(data)[0] === 'COLLECTIONS' ? 
                      <LibraryMusicOutlinedIcon style={{ color: 'white', marginLeft: 10 }} /> : 
                      <FontAwesomeIcon style={{ color: 'white', marginLeft: 10 }} icon={Object.values(data)[2]} />}
                  </ListItemIcon>
                  <ListItemText primary={Object.values(data)[0]} />
                </ListItem>
              </Link>
              )}
            </Tooltip>
          ))}
        </List>
        )}
        {(props.user && props.user.isAuthenticated === true) && (
          <><Divider />
            <List>
              {Object.values(drawerLinks.playlists).map((data, key) => (
                <Tooltip enterDelay={open === false ? 200 : 60000}
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  title={Object.values(data)[0]}
                  placement="right"
                  arrow
                >
                  <Link id={Object.values(data)[1].replace('/', '') + '-button'} to={Object.values(data)[1]}>
                    <ListItem button key={key} >
                      <ListItemIcon>
                        <FontAwesomeIcon style={{ color: 'white', marginLeft: 10 }} icon={Object.values(data)[2]} />
                      </ListItemIcon>
                      <ListItemText primary={Object.values(data)[0]} />
                    </ListItem>
                  </Link>
                </Tooltip>
              ))}
            </List></>
        )}
        {renderAuthLinks()}
        <Divider />
        <List>
          {Object.values(drawerLinks.company).map((data, key) => (
            <Tooltip enterDelay={open === false ? 200 : 60000} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} title={Object.values(data)[0]} placement="right" arrow>
              <Link to={Object.values(data)[1]} >
                <ListItem button key={key}>
                  <ListItemIcon>
                    <FontAwesomeIcon style={{ color: 'white', marginLeft: 10 }} icon={Object.values(data)[2]} />
                  </ListItemIcon>
                  <ListItemText primary={Object.values(data)[0]} />
                </ListItem>
              </Link>
            </Tooltip>
          ))}
        </List>
        <List></List>
      {open && (
        <>
        <Divider />
        <div style={{padding:15}} >
          <section style={{ marginTop: 25 }}>
              <h2 style={{color:'white'}}>Reel Sync Music</h2>
              <ul style={{color:'white'}}>
                  <li>Boston, MA, USA</li>
              </ul>
          </section>
        </div>
      </>
      )}
      <List></List>
      <List></List>
      </Drawer>
      <MasterPortal />
    </Box>
  );
}

function mapStateToProps(state) {
  return { user: state.user };
}

export default connect(mapStateToProps)(Navigation);