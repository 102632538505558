import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import apiconfig from '../../../config/api-config.js';
import { updateNowPlaying, updateIsPlaying, setPlayerStatus, updatePlayerPosition } from '../../FlikTraxStore/Actions/audioPlayerActions';
import { gaPushAudioPreview } from '../../FlikTraxStore/Actions/googleAnalyticsActions';
import { updatePlaylistVisible } from '../../FlikTraxStore/Actions/userActions';
import { useNavigate } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { TableFooter } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlayCircle, faPauseCircle } from '@fortawesome/free-solid-svg-icons';
library.add(faPlayCircle, faPauseCircle);

const MyMiniPlaylist = (props) => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [playlist, setPlaylist] = useState('');

  const [playlistLoading, setPlaylistLoading] = useState(false);

  const currentPlaylist = useSelector((state) => state.user && state.user.userPlaylist);

  const closePlaylist = () => {
    dispatch(updatePlaylistVisible(false));
  }

  const goToPlaylist = () => {
    props.handlePlaylistClose()
    navigate('/my-playlist');
  }

  const updatePlaying = (event) => {

    let ds = event.currentTarget.dataset;
    let targetId = ds.trackid;
    let nowPlaying = ds;
    let currenttid = nowPlaying.trackid;

    if ((props.audioplayer.currentTrackId !== currenttid) && (!props.audioplayer.isPlaying)) {
      props.gaPushAudioPreview(JSON.parse(JSON.stringify(nowPlaying)));
    }

    props.updateNowPlaying(nowPlaying);

    if (props.audioplayer.currentTrackId === targetId) {
      props.updateIsPlaying(!props.audioplayer.isPlaying);
      props.updatePlayerPosition(props.audioplayer.position);
    } else {
      props.updateIsPlaying(true);
      props.updatePlayerPosition(0);
    }
  }

  const renderPlaylist = () => {
    let currentCount = currentPlaylist && Object.keys(currentPlaylist).length;
    console.log('currentCount', currentCount);
    let remaining = currentCount - 5;
    return (

      currentCount > 0 ? (

        <Paper sx={{ m: 1, width: isSmallScreen ? '75vw' : '30vw', opacity: 1 }} elevation={1}>
          <React.Fragment>
            <CardContent >
              <Typography variant="h6" component="div">
                <IconButton size="small" style={{ float: 'right' }} onClick={() => props.handlePlaylistClose()}  >
                  <CancelOutlinedIcon />
                </IconButton>
                <Badge badgeContent={currentCount} color="info">
                  My Playlist
                </Badge>
              </Typography>
              {playlist && playlist.map((plrow, i) => {

                let dataAttrs = {
                  'data-source': plrow.preview_url,
                  'data-tracktitle': plrow.track_title,
                  'data-artist': plrow.artist_name,
                  'data-description': plrow.description,
                  'data-duration': plrow.duration,
                  'data-vocaltype': plrow.vocal_type,
                  'data-trackid': plrow.id,
                  'data-filecompressed': plrow.file_path_compressed,
                  'data-fileuncompressed': plrow.file_path_uncompressed,
                  'data-preview_url': plrow.preview_url
                };

                return (

                  <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell align="left">
                      {/* <IconButton size="small" aria-label="Track Info" color="inherit">
                    <FontAwesomeIcon color="secondary" icon={faPlayCircle} />
                  </IconButton> */}

                      {
                        ((props.audioplayer) && (props.audioplayer.currentTrackId === plrow.id) && (props.audioplayer.isPlaying)) ? (
                          <IconButton size="small" id={props.trackID}  {...dataAttrs} onClick={(event) => { updatePlaying(event) }} size="small" aria-label="Track Info" color="inherit">
                            <FontAwesomeIcon color="secondary" icon={faPauseCircle} size="2x" />
                          </IconButton>
                        ) :
                          <IconButton size="small" id={props.trackID} {...dataAttrs} onClick={(event) => { updatePlaying(event) }} size="small" aria-label="Track Info" color="inherit">
                            <FontAwesomeIcon color="secondary" icon={faPlayCircle} size="2x" />
                          </IconButton>
                      }



                    </TableCell>
                    <TableCell component="th" scope="row">
                      {plrow.track_title}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {plrow.artist_name}
                    </TableCell>
                  </TableRow>
                )
              })}
              {props && props.user && Object.keys(props.user.userPlaylist).length <= 5 && (
                <TableFooter>
                  <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell colspan={3} align="center">
                      <Button color="primary" variant="outlined" onClick={() => goToPlaylist()} >
                        View Playlist
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
              {props && props.user && Object.keys(props.user.userPlaylist).length > 5 && (
                <TableFooter>
                  <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell colspan={3} align="center">
                      <Button color="primary" variant="outlined" onClick={() => goToPlaylist()} >
                        View {remaining} More Tracks
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </CardContent>
          </React.Fragment>
        </Paper>
      ) : (
        <Paper sx={{ m: 1, width:'20vw', opacity: 1 }} elevation={1}>
          <Typography variant="subtitle1" component="div" color="primary">
            Your playlist is currently empty
          </Typography>
        </Paper>
      )
    );
  }

  useEffect(() => {

    const getTrackIds = async () => {
      setPlaylistLoading(true);
      let userID = Auth.user && Auth.user.username;
      try {
        axios({
          baseURL: 'https://api.fliktrax.com/userplaylist',
          method: 'post',
          auth: {
            username: apiconfig.api.APIUSERNAME,
            password: apiconfig.api.APIPASSWORD
          },
          data: {
            userid: userID,
          },
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(response => {
            var oPlaylist = response.data.playlist;
            var truncatedPlaylist = oPlaylist && Object.values(oPlaylist).slice(0, 5).join('","');
            var plaUrl = 'https://api.fliktrax.com/uplaylist?playlist=["' + truncatedPlaylist + '"]';
            axios({
              baseURL: plaUrl,
              method: 'get',
              auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
              },
              headers: {
                "Content-Type": "application/json"
              }
            })
              .then(response => {
                setPlaylist(response.data._embedded.uplaylist);
                setPlaylistLoading(false);
              })
          })

      } catch (error) {
        setPlaylistLoading(false);
      }

    }
    getTrackIds();

  }, []);

  return (

    <Box sx={{ height: 250, position: 'fixed', top: 62, right: 0 }}>

      <Box sx={{ display: 'flex' }}>
        <Card variant="outlined">
          <TableContainer sx={{ padding: 2 }} component={Paper} >
            <Table aria-label="Latest Tracks">
              <TableBody>

                {(playlistLoading && currentPlaylist) ? <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh', minWidth: 375 }}><CircularProgress color="secondary" /></div> : (
                  renderPlaylist()
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>

    </Box>
  );
}

const mapStateToProps = state => ({
  api: state.api,
  audioplayer: state.audioplayer,
  user: state.user,
})

MyMiniPlaylist.propTypes = {
  switchPlayerMode: PropTypes.func.isRequired,
  api: PropTypes.object.isRequired,
  updateNowPlaying: PropTypes.func.isRequired,
  updateIsPlaying: PropTypes.func.isRequired,
  gaPushAudioPreview: PropTypes.func.isRequired,
}


export default connect(mapStateToProps,
  {
    updateNowPlaying,
    updateIsPlaying,
    setPlayerStatus,
    updatePlayerPosition,
    gaPushAudioPreview,
  })(MyMiniPlaylist);