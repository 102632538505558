import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import MyPlaylistTable from './TrackTables/MyPlaylistTable';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

const MyPlaylist = (props) => {

    const userAuthenticated = useSelector((state) => state.user && state.user.isAuthenticated);

    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {



        scrollToTop();

        if (!userAuthenticated) {
            navigate('/login');
        }


    }, []);


    return (
        <Container className="ft-container" maxWidth="md">
            <CssBaseline />
            <Grid className="search-container" container spacing={2}>
                {/* <Grid item xs={12}>
                        <h2 style={{ float: 'left' }}>
                            My Playlist
                        </h2>
                    </Grid> */}
                <Grid item xs={12}>
                    <MyPlaylistTable headerTitle="My Playlist" apiUrl="tvproscategory?" />
                </Grid>
            </Grid>
        </Container>
    )
}
export default (MyPlaylist);
