import {
   UPDATE_NOW_PLAYING,
   UPDATE_FEATURED_PLAYING,
   UPDATE_IS_PLAYING,
   UPDATE_IS_PAUSED,
   UPDATE_POSITION,
   UPDATE_PLAYER_STATUS,
   UPDATE_AUTOPLAY,
   UPDATE_FEATURED_AUTOPLAY,
   TOGGLE_AUTOPLAY,
   TOGGLE_WAVEPLAYER
} from './types';

export const updateNowPlaying = (playing) => dispatch => {
   dispatch({
      type: UPDATE_NOW_PLAYING,
      payload: playing
   })
}

export const updateFeaturedPlaying = (playing) => dispatch => {
   dispatch({
      type: UPDATE_FEATURED_PLAYING,
      payload: playing
   })
}

export const setPlayerStatus = (status) => dispatch => {
   dispatch({
      type: UPDATE_PLAYER_STATUS,
      payload: status
   })
}

export const updateIsPlaying = (status) => dispatch => {
   dispatch({
      type: UPDATE_IS_PLAYING,
      payload: status
   })
}

export const updateIsPaused = (status) => dispatch => {
   dispatch({
      type: UPDATE_IS_PAUSED,
      payload: status
   })
}

export const updatePlayerPosition = (pos) => dispatch => {
   dispatch({
      type: UPDATE_POSITION,
      payload: pos
   })
}

export const updateAutoPlay = (tracks) => dispatch => {
   dispatch({
      type: UPDATE_AUTOPLAY,
      payload: tracks
   })
}

export const updateFeaturedAutoPlay = (tracks) => dispatch => {
   dispatch({
      type: UPDATE_FEATURED_AUTOPLAY,
      payload: tracks
   })
}

export const togglAutoPlay = (play) => dispatch => {
   dispatch({
      type: TOGGLE_AUTOPLAY,
      payload: play
   })
}

export const togglWavePlayer = (waveform) => dispatch => {
   dispatch({
      type: TOGGLE_WAVEPLAYER,
      payload: waveform
   })
}