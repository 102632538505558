import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { titleCase } from "title-case";
import { fetchApiData,  switchApiUrl, switchApiPage, switchMasterMode, setCurrentSearch } from '../../../../FlikTraxStore/Actions/apiActions';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';

export default function MoreLikeFavorites(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { enqueueSnackbar } = useSnackbar();

  const Notify = (notification, variant="info") => {
    enqueueSnackbar(notification, { variant: variant });
  }

  const dispatch = useDispatch();

  const navigate = useNavigate();

  var unique = require('array-unique');

  const onFavoritesClick = () => {
    Notify("This feature coming in 2022!", "warning");
    handleClose();
  }



  const onMoreClick = () => {

    let moreObj = props.trackParent.genres
      //.concat(props.trackParent.styles)
      //.concat(props.trackParent.moods)
      //.concat(props.trackParent.instruments)
      .concat(props.trackParent.keywords);

    let uniq = unique(moreObj.filter(String));

    let moreStr = '';
    uniq.map((str) => (
      moreStr += str + ' '
    ));

    let trackHeader = props.trackParent.track_title;

    var apiUrl = 'search?searchmode=similar&searchtype=1&searchterm=' + encodeURIComponent(moreStr);
    localStorage.setItem('currentsearch', titleCase(trackHeader));
    localStorage.setItem('searchType', 'more');
    localStorage.setItem('searchId', props.trackParent.id);
    dispatch(switchMasterMode('tracks'));
    dispatch(switchApiPage(1));
    dispatch(switchApiUrl(apiUrl));
    dispatch(setCurrentSearch(props.trackParent.id));
    dispatch(fetchApiData());
    navigate('/search');
    document.documentElement.scrollTop = 375;
    handleClose();
  };

  return (
    <div>
      <IconButton
        color="success"
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizOutlinedIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'More Info',
        }}
        anchorOrigin={{
          vertical: 'left',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'left',
          horizontal: 'left',
        }}
      >
        <List dense>
          <ListItem disablePadding>
            <ListItemButton onClick={() => onMoreClick()}>
              <ListItemIcon>
                <SearchOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="More Like This" />
            </ListItemButton>
          </ListItem>
          {/* <Divider /> */}
          <ListItem disablePadding>
            <ListItemButton onClick={() => onFavoritesClick()}>
              <ListItemIcon>
                <FavoriteBorderIcon />
              </ListItemIcon>
              <ListItemText primary="Add To Favorites" />
            </ListItemButton>
          </ListItem>
        </List>
      </Menu>
    </div>
  );
}