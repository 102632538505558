import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import Grid from '@mui/material/Grid';
import SharedPlaylistTable from './TrackTables/SharedPlaylistTable';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

const SharedPlaylist = (props) => {

    let location = useLocation();

    const playlistId = location.search.split('=')[1];

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <Container className="ft-container" maxWidth="md">
            <CssBaseline />
            <Grid className="search-container" container spacing={2}>
                <Grid item xs={12}>
                    <SharedPlaylistTable playlistId={playlistId} headerTitle="My Playlist" apiUrl="tvproscategory?" />
                </Grid>
            </Grid>
        </Container>
    )
}
export default (SharedPlaylist);
