import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { apiIsLoading } from '../../FlikTraxStore/Actions/apiActions';
import axios from 'axios';
import apiconfig from '../../../config/api-config.js';
import CollectionBreadcrumbs from './CollectionComponents/CollectionBreadcrumbs.js';
import Scroll from 'react-scroll-to-element';
import Chip from '@mui/material/Chip';
import PlayPause from './ActionComponents/PlayPause';
import TrackActions from './ActionComponents/TrackActions';
import MixTable from './MixTable'
import LoadingBackdrop from '../../Utilities/LoadingBackdrop';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Badge from '@mui/material/Badge';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
var truncate = require('truncate');

function Row(props) {

    const { row } = props;
    const [open, setOpen] = React.useState(false);
    let variationCount = (row.variation_count > 1) ? row.variation_count - 1 : 0;

    var scrollID = 'row-' + props.trackID;
    var masterID = props.trackID;
    return (

        <React.Fragment>
            <TableRow id={scrollID} hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell align="left">
                    <PlayPause {...props} />
                </TableCell>
                <TableCell component="th" scope="row">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <Scroll type="id" element={scrollID} offset={-75}>
                                <Typography noWrap variant="h6" color="secondary">
                                    <span style={{ marginRight: 10 }}>{truncate(row.track_title, 35)}</span>
                                    <Tooltip TransitionComponent={Fade} title={(open ? 'Hide ' : 'Show ') + variationCount + ' Alternates/Cutdowns'} placement="top" arrow>
                                        <Chip color="default" size="small" onClick={() => { setOpen(!open) }} avatar={<IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => { setOpen(!open) }}
                                        >
                                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>} label={'+ ' + variationCount} />
                                    </Tooltip>
                                </Typography>
                            </Scroll>
                            <Typography noWrap variant="subtitle1">
                                {row.artist_name} ({row.duration})
                            </Typography>

                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell align="left">
                    <TrackActions {...props}
                        playlistMode={true}
                        trackTitle={row.track_title}
                        trackParent={row}
                        trackID={props.trackID}
                        fileMp3={row.file_path_compressed}
                        fileUncompressed={row.file_path_uncompressed}
                    />
                </TableCell>
            </TableRow>
            <TableRow style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit={true}>
                        <Box style={{ borderRadius: 5 }} sx={{ margin: 2, padding: 1, backgroundColor: '#fff' }}>
                            <MixTable {...props} parentID={masterID} trackId={row.id} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


const CollectionTable = (props) => {

    const dispatch = useDispatch();

    const [apiData, setCollectionData] = React.useState(false);
    const dataIsLoading = useSelector((state) => state.api && state.api.isLoading);
    const categoryImage = localStorage.getItem('categoryImage');
    const categoryTitle = localStorage.getItem('categoryTitle');

    useEffect(() => {
        if (!apiData) {
            dispatch(apiIsLoading(true));
            var axiosUrl = 'https://api.fliktrax.com/newrelease';

            axios({
                baseURL: axiosUrl,
                method: 'get',
                auth: {
                    username: apiconfig.api.APIUSERNAME,
                    password: apiconfig.api.APIPASSWORD
                },
                params: {
                    newrelease_id: props.collectionID,
                    page: 1,
                    filters: [],

                },
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(response => {
                    setCollectionData(response.data);
                    dispatch(apiIsLoading(false));
                    props.scrollToTop();
                })


        }

    }, [apiData]);


    return (
        <React.Fragment >
            <CssBaseline />
            <LoadingBackdrop open={dataIsLoading} />

            <Grid container>
                <Grid item xs={12} display={{ sm: "none", md: "block" }}>
                    <h1>
                        <Avatar
                            // style={{ borderRadius: 5 }}
                            alt={props.headerTitle}
                            src={categoryImage}
                            sx={{ width: 100, height: 100, float: 'right', marginRight: 5, marginBottom: 5 }}

                        />
                        <Badge max={15000} badgeContent={apiData && apiData.total_items} color="info">
                            {categoryTitle}
                        </Badge>
                        <CollectionBreadcrumbs categoryTitle={categoryTitle} />
                    </h1>
                </Grid>
            </Grid>
            <TableContainer style={{ borderRadius: 5 }} sx={{ padding: 2 }} component={Paper} >

                <Table aria-label="Latest Tracks">
                    {/* <TableHead>
                        <TableRow >
                            <h2>
                                    <Badge max={999} badgeContent={apiData && apiData.total_items} color="info">{props.headerTitle}
                                    </Badge>
                                </h2>
                            <Paginator />

                        </TableRow>
                    </TableHead> */}
                    <TableBody>
                        {apiData && apiData._embedded.newrelease.map((data) => {
                            //console.log(props.api.embeddedData[0])
                            let dataAttrs = {
                                'data-source': data.preview_url,
                                'data-tracktitle': data.track_title,
                                'data-artist': data.artist_name,
                                'data-description': data.description,
                                'data-duration': data.duration,
                                'data-vocaltype': data.vocal_type,
                                'data-trackid': data.id,
                                'data-filecompressed': data.file_path_compressed,
                                'data-fileuncompressed': data.file_path_uncompressed
                            };
                            return (
                                <Row key={data.id} row={data} dataAttr={dataAttrs} trackID={data.id} />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment >
    );

}

export default CollectionTable;