import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import CategoryTable from './TrackTables/CategoryTable';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

const Collection = (props) => {

    const categoryId = (new URLSearchParams(window.location.search)).get("cid");

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {

        scrollToTop();

    }, []);

    return (
        <Container className="ft-container" maxWidth="md">
            <CssBaseline />
            <Grid className="search-container" container spacing={2}>
                {/* <Grid item xs={12}>
                    <h2 style={{ float: 'left' }}>
                        Categories
                    </h2>
                </Grid> */}

                <Grid item xs={12}>
                    <CategoryTable scrollToTop={() => scrollToTop()} categoryID={categoryId} />
                </Grid>
            </Grid>

        </Container>
    );

}
export default (Collection);
