import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const CustomMusic = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {

        scrollToTop();

    }, []);


    return (
        <Container className="ft-container" maxWidth="md">
            <h1>404: Not Found</h1>
            <Box >
                <CssBaseline />
                <Box sx={{ flexGrow: 1, p: 4 }} >
                    <Typography variant="h5" component="div">
                        That page isn't here but we can help you <Link style={{color:'#fe6204'}} to='/search'>find what you want.</Link>
                    </Typography>
                    <Typography paragraph>
                        
                    </Typography>
                </Box>
            </Box>
        </Container>
    )
}

export default CustomMusic;