import React, { use } from 'react';
import { useNavigate } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { Button, CardActionArea, CardActions } from '@mui/material';

const ShowBoxCard = (props) => {

  const navigate = useNavigate();
  const handleClick = () => {
    localStorage.setItem('categoryImage', props.cardData.image);
    localStorage.setItem('categoryTitle', props.cardData.title);
    navigate('/showbox?collectionId=' + props.cardData.slug);
  }
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));


  return (
    <>
      <Card sx={{ width:300, height: 350}}>
        <ButtonBase
          onClick={() => handleClick()}
        >
          <CardActionArea>
            <CardMedia
              component="img"
              sx={{ width:300, height: 300}}
              //width="400"
              //height="800"
              image={props.cardData.image}
              alt="ReelSync Music"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div" color="primary">
                {props.cardData.title}
              </Typography>
               {props.cardData.description &&  (
              <Typography p={1} variant="subtitle1" style={{fontSize:'1.15em', fontStyle:'italic', textAlign:'center'}}>
                {props.cardData.description}.
              </Typography>
               )}
            </CardContent>
          </CardActionArea>
        </ButtonBase>
        <CardActions >
          {/* <Button size="small" color="primary">
            Share
          </Button> */}
        </CardActions>
      </Card>
    </>
  );
}

export default ShowBoxCard;