import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiconfig from '../../../config/api-config.js'
import Scroll from 'react-scroll-to-element';
import PlayPause from './ActionComponents/PlayPause';
import SharedTrackActions from './ActionComponents/SharedTrackActions';
import LoadingBackdrop from '../../Utilities/LoadingBackdrop';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Badge from '@mui/material/Badge';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

var truncate = require('truncate');


function Row(props) {

    const { row } = props;

    return (

        <React.Fragment>
            <TableRow id={'plRow-' + props.trackID} hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell align="left">
                    <PlayPause {...props} />
                </TableCell>
                <TableCell component="th" scope="row">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <Scroll type="id" element={props.trackID} offset={-75}>
                                <Typography noWrap variant="h6" color="secondary">
                                    <span style={{ marginRight: 10 }}>{truncate(row.track_title, 35)}</span>
                                </Typography>
                            </Scroll>
                            <Typography noWrap variant="subtitle1">
                                {row.artist_name} ({row.duration})
                            </Typography>

                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell align="left">
                    <SharedTrackActions {...props} 
                        playlistMode={false}
                        trackTitle={row.track_title}
                        trackParent={row}
                        trackID={props.trackID}
                        fileMp3={row.file_path_compressed}
                        fileUncompressed={row.file_path_uncompressed}
                    />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


const SharedPlaylistTable = (props) => {

    const [sharedplaylist, setSharedPlaylist] = useState(null);
    const [sharedplaylistTitle, setSharedPlaylistTitle] = useState(null);
    const [playlistLoading, setPlaylistLoading] = useState(false);


    const renderPlaylist = () => {
        return (
            sharedplaylist && sharedplaylist.map((data, i) => {
                let dataAttrs = {
                    'data-source': data.preview_url,
                    'data-tracktitle': data.track_title,
                    'data-artist': data.artist_name,
                    'data-description': data.description,
                    'data-duration': data.duration,
                    'data-vocaltype': data.vocal_type,
                    'data-trackid': data.id,
                    'data-filecompressed': data.file_path_compressed,
                    'data-fileuncompressed': data.file_path_uncompressed
                };
                return (
                    <Row key={data.id} row={data} dataAttr={dataAttrs} trackID={data.id} />
                );
            })
        );
    }

    useEffect(() => {
        if (!sharedplaylist || sharedplaylist.length === 0) {

            async function getUserSharedPlaylist() {
                var axiosUrl = 'https://api.fliktrax.com/userplaylist';

                await axios({
                    baseURL: axiosUrl,
                    method: 'post',
                    auth: {
                        username: apiconfig.api.APIUSERNAME,
                        password: apiconfig.api.APIPASSWORD
                    },
                    data: {
                        cmd: 'getshared',
                        playlistid: props.playlistId
                    },
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => {
                        console.log(response.data)
                        setSharedPlaylist(response.data.playlist);
                        setSharedPlaylistTitle(response.data.title);
                    })
            }
            getUserSharedPlaylist();
        }
    }, [props.playlistId, sharedplaylist]);

    return (
        <React.Fragment >

            <CssBaseline />
            <LoadingBackdrop open={playlistLoading} />
            <Grid container spacing={6} >
                <Grid item xs={6}>
                    <h2>
                        <Badge max={15000} badgeContent={sharedplaylist && sharedplaylist.length} color="info">
                            {sharedplaylistTitle}
                        </Badge>
                    </h2>
                </Grid>
            </Grid>
            <TableContainer sx={{ padding: 2 }} component={Paper} >
                <Table aria-label="Latest Tracks">
                    <TableBody>
                        {sharedplaylist && sharedplaylist.length > 0 && (
                            renderPlaylist()
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );

}

export default SharedPlaylistTable;
