import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from "react-redux";
import { loadLocalPlaylist, fetchSavedPlaylists } from '../../FlikTraxStore/Actions/userActions';
import SavedPlaylistsAccordian from './PlaylistComponents/SavedPlaylistsAccordian';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingBackdrop from '../../Utilities/LoadingBackdrop';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

const SavedPlaylistsTable = (props) => {

    const dispatch = useDispatch();
    const dataIsLoading = useSelector((state) => state.api && state.api.isLoading);
    const savedPlaylistsReducer = useSelector((state) => state.user && state.user.savedPlaylists);
    const savedPlaylists = savedPlaylistsReducer;
    const [playlistsLoading, setPlaylistsLoading] = useState(false);



    useEffect(() => {
        setPlaylistsLoading(true)
        if (!savedPlaylists) {
            dispatch(fetchSavedPlaylists());
        }
        setPlaylistsLoading(false)
    }, []);

    return (
        <React.Fragment >
            <CssBaseline />
            <LoadingBackdrop open={dataIsLoading} />
            <Grid container spacing={6} >
                <Grid item xs={12}>
                    <h2>
                        <Badge max={15000} badgeContent={savedPlaylists && Object.values(savedPlaylists).length - 1} color="info">
                            Saved Playlists
                        </Badge>
                    </h2>
                    <Divider style={{marginBottom:50}} />
                </Grid>
            </Grid>
            <Grid container sx={{ padding: 0 }} component={Paper} >
                <Grid item xs={12}>
                   
                    {playlistsLoading ? <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh', minWidth: 375 }}><CircularProgress color="secondary" /></div> : (
                        <SavedPlaylistsAccordian savedPlaylists={savedPlaylists && Object.values(savedPlaylists)} />
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );

}

SavedPlaylistsTable.propTypes = {
    loadLocalPlaylist: PropTypes.func.isRequired,
    fetchSavedPlaylists: PropTypes.func.isRequired,
}


const mapStateToProps = state => ({
    api: state.api,
    audioplayer: state.audioplayer,
    user: state.user,
})

export default connect(mapStateToProps,
    {
        loadLocalPlaylist,
        fetchSavedPlaylists
    })(SavedPlaylistsTable);
