import React, { useEffect } from 'react';
import './App.css';
import { Auth } from 'aws-amplify';
import { Provider } from 'react-redux';
import FlikTraxStore from './components/FlikTraxStore/FlikTraxStore';
import { updateAuthStatus, setAuthUser, setAuthGroups, updateAuthenticating, loadLocalPlaylist, updatePlaylistCounts, loadSavedPlaylistCount, updateUserTheme } from './components/FlikTraxStore/Actions/userActions';

import AppRoutes from './AppRoutes';
import TagManager from 'react-gtm-module';

function TVPros() {

  const tagManagerArgs = {
    gtmId: 'GTM-NCNS68C'
  }

  TagManager.initialize(tagManagerArgs);

  const [isAuthenticating, Authenticating] = React.useState(false);

  const [authenticated, isAuthenticated] = React.useState(false);

  const [user, setUser] = React.useState(false);

  useEffect(() => {

    localStorage.getItem('searchType');

    (localStorage.getItem('currentTheme') !== null) ?
      FlikTraxStore.dispatch(updateUserTheme(localStorage.getItem('currentTheme'))) :
      FlikTraxStore.dispatch(updateUserTheme('dark'));

    const getUser = async () => {
      try {
        const session = await Auth.currentSession();
        isAuthenticated(true);
        FlikTraxStore.dispatch(updateAuthStatus(true));
        const user = await Auth.currentAuthenticatedUser();
        setUser(user);
        FlikTraxStore.dispatch(setAuthUser(user));
        //FlikTraxStore.dispatch(setAuthGroups(user.signInUserSession.accessToken.payload['cognito:groups']));
        FlikTraxStore.dispatch(loadLocalPlaylist());
        FlikTraxStore.dispatch(updatePlaylistCounts());
        FlikTraxStore.dispatch(loadSavedPlaylistCount());
      } catch (error) {
        //console.log(error);
      }
      Authenticating(isAuthenticating);
      FlikTraxStore.dispatch(updateAuthenticating(false));
    }
    getUser();
  }, []);

  return (
    <Provider store={FlikTraxStore}>
        <AppRoutes />
    </Provider>
  );
}

export default TVPros;