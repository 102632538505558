import React from 'react';
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { Button, CardActionArea, CardActions } from '@mui/material';

const CategoryCard = (props) => {

  const navigate = useNavigate();

  const handleClick = () => {
    localStorage.setItem('categoryImage', props.cardData.image);
    localStorage.setItem('categoryTitle', props.cardData.name);
    navigate('/category?cid=' + props.cardData.slug);
  }

  return (
    <>
      <Card sx={{ maxWidth: 250, height:360 }}>
        <ButtonBase
          onClick={() => handleClick()}
        >
          <CardActionArea>
            <CardMedia
              component="img"
              width="250"
              height="300"
              style={{width:250, maxHeight:300}}
              image={props.cardData.image}
              alt="TVPros"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div" color="secondary">
                {props.cardData.name}
              </Typography>
            </CardContent>
          </CardActionArea>
        </ButtonBase>
        <CardActions>
          {/* <Button size="small" color="primary">
            Share
          </Button> */}
        </CardActions>
      </Card>
    </>
  );
}

export default CategoryCard;