import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { modalIsLoading, fetchPublishingData, switchMasterMode, switchApiUrl, switchApiPage, fetchApiData } from '../../../../FlikTraxStore/Actions/apiActions';
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { titleCase } from "title-case";


const style = {
    position: 'absolute',
    height: '85vh',
    overflow: 'scroll',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85vw',
    bgcolor: '#fff',
    border: '1px solid #fff',
    boxShadow: 24,
    p: 4,
    zIndex: '4000',
};

function encIOSURL(str) {
    return str.replace("”", '"').replace("“", '"').replace("’", "'").replace("‘", "'").replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"').replace(/[\u2013\u2014]/g, '-').replace(/[\u2026]/g, '...');
}

const TrackInfo = (props) => {

    const [infoOpen, setInfoOpen] = React.useState(false);

    const [infoloading, setInfoLoading] = React.useState(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const publishData = useSelector((state) => state.api && state.api.splitsData);

    const modalLoading = useSelector((state) => state.api && state.api.modalLoading);

    const handleClickAway = () => {
        setInfoOpen(false);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    const handleChipClick = React.useCallback((value) => {
        setInfoOpen(false);
        localStorage.setItem('currentsearch', titleCase(value));
        localStorage.removeItem('searchType');
        var iOSstr = encIOSURL(value);
        var encsearch = encodeURIComponent(iOSstr);
        var apiUrl = 'search?&searchtype=2&searchterm=' + encsearch;
        dispatch(switchMasterMode('search'));
        dispatch(switchApiPage(1));
        dispatch(switchApiUrl(apiUrl));
        dispatch(fetchApiData());
        props.handleMobileMenuClose();
        navigate('/search');
        scrollToTop();
    }, [navigate, dispatch]);



    const getTrackInfo = (trackID) => {
        modalIsLoading(true);
        setInfoOpen(true);
        dispatch(fetchPublishingData(props.trackID));
        setInfoLoading(false);
        props.handleMobileMenuClose();
    };

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.primary,
    }));

    const renderPubInfo = (data) => {
        let listObj = data && data.split('/');
        return (
            <List dense={true}>
                {listObj && listObj.map((datastr) => {
                    return (
                        <ListItem>
                            <ListItemText
                                primary={datastr.toUpperCase()}
                            />
                        </ListItem>
                    )
                })}
            </List>
        );
    }

    const [genresData, setGenresData] = React.useState(props && props.trackParent && props.trackParent.genres);
    const [keywordsData, setKeywordsData] = React.useState(props && props.trackParent && props.trackParent.keywords);
    const [moodsData, setMoodsData] = React.useState(props && props.trackParent && props.trackParent.moods);
    const [stylesData, setStylesData] = React.useState(props && props.trackParent && props.trackParent.styles);

    const renderChips = (data) => {
        //console.log(data.length)
        return (
            <>
                {data && data.length > 0  && data.map((value) => {
                    return (
                        <Chip size="small" variant="outlined" onClick={() => handleChipClick(value)} style={{ margin: 5 }}
                            label={value.toUpperCase()}
                        />
                    )
                })
                }
            </>
        )
    }

    return (
        <>
            <Modal
                xs={12}
                open={infoOpen}
                onClose={handleClickAway}
            >

                <Box id="infoModalBox" sx={style}>
                    {modalLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}><CircularProgress color="secondary" /></div> : (
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <IconButton style={{ float: 'right' }} onClick={() => setInfoOpen(false)} sx={{ padding: 1 }} color="secondary">
                                    {!modalLoading && (<CloseRoundedIcon />)}
                                </IconButton>
                                <Item>
                                    <Typography variant="h5" container="span" color="secondary" nowrap>
                                        {publishData && publishData.trackinfo.track_title} ( {props && props.trackParent && props.trackParent.artist_name} )
                                    </Typography>
                                </Item>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Item>
                                    <Typography variant="h6" container="span">
                                        Artist:
                                    </Typography>
                                    <Typography display="inline-block" variant="subtitle1" container="div">
                                        {renderPubInfo(publishData && publishData.trackinfo.artist_name)}
                                    </Typography>

                                </Item>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/* <Item>
                                    <Typography variant="h6" container="span">
                                        Publishers:
                                    </Typography>
                                    <Typography variant="subtitle1" container="div">
                                        {renderPubInfo(publishData && publishData.publishinginfo)}
                                    </Typography>
                                </Item> */}
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Item>
                                    <Typography variant="h6" container="span">
                                        Genres:
                                    </Typography>
                                    <Typography variant="subtitle1" container="div">
                                        {publishData && publishData.trackinfo.genres[0].toUpperCase()}
                                    </Typography>
                                </Item>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Item>
                                    <Typography variant="h6" container="span">
                                        Vocal Type:
                                    </Typography>
                                    <Typography variant="subtitle1" container="div">
                                        {publishData && publishData.trackinfo.vocal_type.toUpperCase()}
                                    </Typography>
                                </Item>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Item>
                                    <Typography variant="h6" container="span">
                                        Beats Per Minute:
                                    </Typography>
                                    <Typography variant="subtitle1" container="div">
                                        {publishData && publishData.trackinfo.beats_per_minute}
                                    </Typography>
                                </Item>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Item>
                                    <Typography p={1} variant="h6" container="span">
                                        Description:
                                    </Typography>
                                    <Typography p={2} variant="subtitle1" container="div">
                                        {publishData && publishData.trackinfo.description.toUpperCase()}
                                    </Typography>
                                </Item>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} p={2}>
                                <Typography variant="h6" container="span">
                                    Sub Genres:
                                </Typography>
                                {renderChips(genresData)}
                            </Grid>

                            <Grid item xs={12} p={2}>
                                <Typography variant="h6" container="span">
                                    Moods:
                                </Typography>
                                {renderChips(moodsData)}
                            </Grid>
                            <Grid item xs={12} p={2}>
                                <Typography variant="h6" container="span">
                                    Styles:
                                </Typography>
                                {renderChips(stylesData)}
                            </Grid>                            
                            <Grid item xs={12} p={2}>
                                <Typography variant="h6" container="span">
                                    Keywords:
                                </Typography>
                                {renderChips(keywordsData)}
                            </Grid>

                        </Grid>
                    )}
                </Box>

            </Modal>

            <React.Fragment>
                <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    title="Track Info"
                    placement="top"
                    arrow
                >
                    <IconButton onClick={() => getTrackInfo()} sx={{ padding: 1 }} size="small" aria-label="Track Info" color="secondary">
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            </React.Fragment>
        </>


    );
}

function mapStateToProps(state) {
    return {
        api: state.api,
    };
}

export default connect(mapStateToProps, {
    fetchPublishingData,
    modalIsLoading,
})(TrackInfo);