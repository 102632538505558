import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import apiconfig from '../../config/api-config.js';
import { useNavigate } from "react-router-dom";
import LoadingBackdrop from '../Utilities/LoadingBackdrop';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CampaignIcon from '@mui/icons-material/Campaign';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Box, Button, TextField, FormHelperText } from '@mui/material';
import { Formik } from 'formik'
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompactDisc } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faCompactDisc);

const style = {
    position: 'relative',
    minHeight: '75vh',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '55%',
    // bgcolor: '#272727',
    border: '1px solid #fff',
    boxShadow: 1,
    p: 3,
}

const Request = (props) => {

    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {

        scrollToTop();

    }, []);


    const [loading, setLoading] = React.useState(false);

    const [form, setForm] = React.useState('request');

    const currentUserAttributes = props.user && props.user.isAuthenticated && props.user.user ? props.user.user.attributes : null;

    const renderSuccess = () => {
        return (
            <Container className="ft-container" maxWidth="md">
                <Box sx={style} >
                    <img style={{ maxWidth: 150, float: 'right' }} src="/images/logos/reelsync-logo-bw.jpg" alt="ReelSync Music" />
                    <h1>Request</h1>
                    <Grid container spacing={4} p={4}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Thank you for your request! We will get back to you within the next business day.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography container="span">
                                For additional information, please email us at <a style={{ color: "#fe6204" }} href="mailto:info@fliktrax.com">info@fliktrax.com</a>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography container="span">
                                For support, please email us at <a style={{ color: "#fe6204" }} href="mailto:support@fliktrax.com">support@fliktrax.com</a>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        );
    }

    const renderRequestForm = () => {

        return (

            <Container className="ft-container" maxWidth="md">
                <LoadingBackdrop open={loading} />
                <Box sx={style} >
                    <img style={{ maxHeight: 110, float: 'right' }} src="/images/logos/reelsync-logo-bw.jpg" alt="TVPros" />
                    <h1>Request</h1>
                    <Divider />
                    <Typography variant="h6" p={1}>
                        Custom Cues, Themes, Score or Playlist
                    </Typography>
                    <Typography container="p" p={1}>
                        Send us a brief, reference track(s) and/or description of the exact type of music you need. Fliktrax's 'TV Pros' team will immediately begin to create and curate options.
                    </Typography>
                    <Formik
                        initialValues={{
                            name: currentUserAttributes !== null ? currentUserAttributes['custom:firstname'] + ' ' + currentUserAttributes['custom:lastname'] : "",
                            email: currentUserAttributes !== null ? currentUserAttributes['email'] : "",
                            reference: "",
                            comment: "",

                        }}
                        validationSchema={
                            Yup.object().shape({

                                email: currentUserAttributes !== null ? Yup.string() : 
                                                                        Yup.string().required('Email is required.')
                                                                                    .trim()
                                                                                    .max(255),  

                                name: currentUserAttributes !== null ? 
                                                                        Yup.string() : 
                                                                        Yup.string().required('Name is required.')
                                                                                    .trim()
                                                                                    .min(4, 'Must include at least 4 characters.')
                                                                                    .max(100, 'Name more than 100 characters long.'), 

                                reference: Yup.lazy((value) =>
                                    /^data/.test(value)
                                        ? Yup.string().matches(
                                            /^data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*)$/i,
                                            'Must be a valid data URI',
                                        )
                                        : Yup.string().url('Must be a valid URL'),
                                ),
                                
                                comment: Yup.string()
                                    .min(8, 'Must include at least 8 characters.')
                                    .max(1000, 'Request Informationt cannot more than 1000 characters long.')
                                    .trim()
                                    .required('Request Information is required.'),
                            })
                        }
                        onSubmit={async (value, { setErrors, setStatus, setSubmitting }) => {
                            try {
                                setLoading(true);
                                console.log(value);
                                let name = "Name: " + value.name + "\r\n";
                                let email = 'Email: ' + value.email;
                                let referenceTrack = 'Reference Track: ' + value.reference + "\r\n";
                                let request = 'Request: %%' + value.comment + "\r\n";
                                let encodedString = encodeURIComponent(name + "%" + email + "%" + referenceTrack + "%" + request);
                                axios({
                                    baseURL: 'https://api.fliktrax.com/services',
                                    method: 'post',
                                    auth: {
                                        username: apiconfig.api.APIUSERNAME,
                                        password: apiconfig.api.APIPASSWORD
                                    },
                                    data: {
                                        service: 'notify-service',
                                        'subject': 'New TVPros Music Request',
                                        'message': encodedString
                                    },
                                    headers: {
                                        "Content-Type": "application/json"
                                    }
                                })
                                    .then(response => {
                                        console.log(response.data)
                                        setStatus({ success: false });
                                        setLoading(false);
                                        setForm('success');
                                    })
                            } catch (err) {
                                console.log(err)
                                setStatus({ success: false })
                                setErrors({ submit: err.message });
                                setSubmitting(false)
                                setLoading(false);
                            }
                        }}
                    >
                        {({ errors,
                            values,
                            handleSubmit,
                            handleBlur,
                            handleChange,
                            isSubmitting,
                            touched,
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={1} p={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            disabled={currentUserAttributes !== null ? true : false}
                                            error={Boolean(touched.name && errors.name)}
                                            fullWidth
                                            helperText={touched.name && errors.name}
                                            label={currentUserAttributes === null ? "Name" : ""}
                                            margin="normal"
                                            name="name"
                                            type="text"
                                            letiant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={currentUserAttributes !== null ? currentUserAttributes['custom:firstname'] + ' ' + currentUserAttributes['custom:lastname'] : values.name}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            disabled={currentUserAttributes !== null ? true : false}
                                            error={Boolean(touched.email && errors.email)}
                                            fullWidth
                                            helperText={touched.email && errors.email}
                                            label="Email"
                                            margin="normal"
                                            name="email"
                                            type="email"
                                            letiant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={currentUserAttributes !== null ? currentUserAttributes['email'] : values.email}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={Boolean(touched.reference && errors.reference)}
                                            fullWidth
                                            helperText={touched.reference && errors.reference}
                                            label="Reference Track URL (Optional: Link to SoundCloud, YouTube etc.)"
                                            margin="normal"
                                            name="reference"
                                            type="text"
                                            letiant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.reference}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={Boolean(touched.comment && errors.comment)}
                                            fullWidth
                                            helperText={touched.comment && errors.comment}
                                            multiline={true}
                                            rows={5}
                                            label="Request Information"
                                            margin="normal"
                                            name="comment"
                                            type="text"
                                            letiant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.comment}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {errors.submit && (
                                            <Box sx={{
                                                mt: 3
                                            }}>
                                                <FormHelperText style={{ fontSize: '1.1em' }} error>
                                                    {errors.submit}
                                                </FormHelperText>
                                            </Box>
                                        )}
                                        <Button
                                            variant="primary"
                                            fullWidth
                                            size="large"
                                            color="primary"
                                            bgcolor="primary"
                                            disabled={isSubmitting}
                                            type="submit"
                                        >
                                            <CampaignIcon style={{ marginRight: 5 }} /> Request{loading === true && (<FontAwesomeIcon style={{ marginLeft: 10 }} icon={faCompactDisc} size="2x" spin />)}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                    {/* <p style={{ marginTop: 25 }}>
                        <h4>Password Policy</h4>
                        Password must:
                        <ul>
                            <li>Contain minimum of eight(8) characters</li>
                            <li>Contain at least one(1) number</li>
                            <li>Contain at least one(1) uppercase character</li>
                        </ul>
                    </p> */}
                </Box>
            </Container >
        )
    }

    switch (form) {
        case 'success':
            return renderSuccess();

        default:
            return renderRequestForm();
    }


}

const mapStateToProps = state => ({
    user: state.user,
})


export default connect(mapStateToProps, {})(Request);