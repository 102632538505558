
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchFeaturedPlaylists } from '../../FlikTraxStore/Actions/apiActions';
import { updateFeaturedPlaying, updateAutoPlay, updateNowPlaying, updateIsPlaying, updatePlayerPosition } from '../../FlikTraxStore/Actions/audioPlayerActions';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { CardActionArea } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBell } from '@fortawesome/free-solid-svg-icons';

library.add(faBell)

const FeaturedCollection = (props) => {

    const theme = useTheme();

    const [featuredLoading, setFeaturedLoading] = React.useState(false);

    const [featuredShowing, setFeaturedShowing] = React.useState(true);

    const dispatch = useDispatch();

    const featuredPlaylistsReducer = useSelector((state) => state.api && state.api.featuredPlaylist && state.api.featuredPlaylist[0].featured_data);

    const featuredPlaylistsTracks = useSelector((state) => state.api && state.api.featuredPlaylist && state.api.featuredPlaylist[1].tracks);

    const audioPlayer = useSelector((state) => state.audioplayer);

    const isPlaying = useSelector((state) => state.audioplayer && state.audioplayer.isPlaying);

    const featuredPlaying = useSelector((state) => state.audioplayer && state.audioplayer.featuredPlaying);

    const [currentIndex, setCurrentIndex] = React.useState(0);

    const playToggle = (direction) => {

        if (!featuredPlaying) {
            dispatch(updateFeaturedPlaying(true));
        }

        let autoPlayTracks = audioPlayer.autoplaytracks;
        let currentTrackId = audioPlayer.currentTrackId;

        let currentKey = null;
        autoPlayTracks.map((track, index) => {
            if (track.id === currentTrackId) {
                currentKey = index;
            };
        });

        let nextKey = null;

        switch (direction) {
            case 'back':
                nextKey = currentKey - 1;
                break;
            default:
                nextKey = currentKey + 1;
        }

        setCurrentIndex(nextKey);

        let nextTrack = autoPlayTracks[nextKey];

        let ds = {
            'source': nextTrack.preview_url,
            'tracktitle': nextTrack.track_title,
            'artist': nextTrack.artist_name,
            'description': nextTrack.description,
            'duration': nextTrack.duration,
            'vocaltype': nextTrack.vocal_type,
            'trackid': nextTrack.id,
            'filecompressed': nextTrack.file_path_compressed,
            'fileuncompressed': nextTrack.file_path_uncompressed
        };

        dispatch(updateNowPlaying(ds));
        dispatch(updateIsPlaying(true));
        dispatch(updatePlayerPosition(0));
    }

    const playFeatured = () => {

        if (!featuredPlaying) {
            dispatch(updateFeaturedPlaying(true));
        }

        dispatch(updateAutoPlay(featuredPlaylistsTracks));

        let firstFeatured = Object.values(featuredPlaylistsTracks)[0];
        let ds = {
            'source': firstFeatured.preview_url,
            'tracktitle': firstFeatured.track_title,
            'artist': firstFeatured.artist_name,
            'description': firstFeatured.description,
            'duration': firstFeatured.duration,
            'vocaltype': firstFeatured.vocal_type,
            'trackid': firstFeatured.id,
            'filecompressed': firstFeatured.file_path_compressed,
            'fileuncompressed': firstFeatured.file_path_uncompressed
        };

        let targetId = firstFeatured.id;
        let nowPlaying = ds;
        let currenttid = nowPlaying.trackid;

        dispatch(updateNowPlaying(nowPlaying));

        if (audioPlayer.currentTrackId === targetId) {
            dispatch(updateIsPlaying(!isPlaying));
            dispatch(updatePlayerPosition(audioPlayer.position));
        } else {
            dispatch(updateIsPlaying(true));
            dispatch(updatePlayerPosition(0));
        }
    }

    const pauseFeatured = () => {
        dispatch(updateIsPlaying(false));
        dispatch(updateFeaturedPlaying(false));
    }

    const setLinkSearch = () => {
        localStorage.setItem('categoryTitle', featuredPlaylistsReducer.name);
    }

    useEffect(() => {
        if (!featuredPlaylistsReducer) {
            setFeaturedLoading(true);
            dispatch(fetchFeaturedPlaylists());
            setFeaturedLoading(false);
        }
    }, [dispatch, featuredPlaylistsReducer]);


    const RenderFeaturedCard = () => {

        return (
            <>
                <ListItem button onClick={() => setFeaturedShowing(!featuredShowing)} >
                    <ListItemIcon >
                        <FontAwesomeIcon style={{ color: 'white', marginLeft: 10 }} icon={faBell} />
                    </ListItemIcon>
                    <ListItemText primary="FEATURED" />
                    {featuredShowing ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </ListItem>
                {featuredPlaylistsReducer && (

                    <Collapse orientation="vertical" in={featuredShowing}>
                        <Card sx={{ maxWidth: 210, marginLeft: 1.3 }}>
                            <CardActionArea>
                                <Box sx={{ position: 'relative' }}>
                                    <a href={"/collection?collectionId=" + featuredPlaylistsReducer.slug} onClick={() => setLinkSearch()}>
                                        <CardMedia
                                            component="img"
                                            height="250"
                                            image={featuredPlaylistsReducer.image}
                                        />
                                    </a>
                                    {featuredPlaying && (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                width: '100%',
                                                bgcolor: 'rgba(0, 0, 0, 0.85)',
                                                color: 'white',
                                                padding: '10px',
                                            }}
                                        >
                                            <Typography style={{ textAlign: 'center' }} variant="subtitle1">{audioPlayer.currentTrackTitle}</Typography>
                                            <Typography style={{ textAlign: 'center' }} variant="subtitle2">{audioPlayer.currentArtist}</Typography>
                                        </Box>
                                    )}
                                </Box>

                                <CardContent>
                                    {/* <Typography gutterBottom variant="h6" component="div" pl={1}>
                                        Featured Collection
                                    </Typography>
                                    <Divider /> */}
                                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 3.5, pb: 0 }}>
                                        <IconButton disabled={currentIndex === 0 ? true : false} size="small" aria-label="previous">
                                            <SkipPreviousIcon onClick={() => playToggle('back')} />
                                        </IconButton>
                                        <IconButton size="small" aria-label="play/pause" >
                                            {(!featuredPlaying) ? (
                                                <PlayArrowIcon sx={{ height: 25, width: 35 }} onClick={() => playFeatured()} />
                                            ) : (
                                                <PauseIcon sx={{ height: 25, width: 35 }} onClick={() => pauseFeatured()} />
                                            )}
                                        </IconButton>
                                        <IconButton size="small" aria-label="next" >
                                            <SkipNextIcon onClick={() => playToggle('next')} />
                                        </IconButton>
                                    </Box>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Collapse>
                )}
            </>
        );


    }

    return RenderFeaturedCard();

}

export default FeaturedCollection;
