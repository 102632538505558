import React from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { switchMasterMode, switchApiUrl, switchApiPage, fetchApiData, setVocalFilter } from '../../../FlikTraxStore/Actions/apiActions';
import TextField from '@mui/material/TextField';
import FilterAltIcon from '@mui/icons-material/FilterAltTwoTone';
import FilterAltOff from '@mui/icons-material/FilterAltOffTwoTone';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Grow from '@mui/material/Grow';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import SearchIcon from '@mui/icons-material/Search';

function encIOSURL(str) {
    return str.replace("”", '"').replace("“", '"').replace("’", "'").replace("‘", "'").replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"').replace(/[\u2013\u2014]/g, '-').replace(/[\u2026]/g, '...');
}

const SearchField = React.forwardRef(
    (
        {
            cancelOnEscape,
            className,
            classes,
            closeIcon,
            disabled,
            onCancelSearch,
            onRequestSearch,
            searchIcon,
            style,
            ...inputProps
        },
        ref
    ) => {
        const inputRef = React.useRef();
        const [value, setValue] = React.useState(inputProps.value);
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const theme = useTheme();

        const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

        const options = ['Vocal and Instrumental', 'Female Vocal', 'Male Vocal', 'Instrumental Only'];

        const [open, setOpen] = React.useState(false);
        const anchorRef = React.useRef(null);
        const [selectedIndex, setSelectedIndex] = React.useState(false);

        const handleMenuItemClick = (event, index) => {
            setSelectedIndex(index);
            let filter = null;
            switch (index) {
                case 1:
                    filter = "female";
                    break;

                case 2:
                    filter = "male";
                    break;

                case 3:
                    filter = "instrumental";
                    break;

                default: filter = '';
            }

            dispatch(setVocalFilter(filter));
            setOpen(false);
        };

        const handleToggle = () => {
            setOpen((prevOpen) => !prevOpen);
        };

        const handleClose = (event) => {
            setOpen(false);
        }


        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        };

        React.useEffect(() => {
            setValue(inputProps.value);
        }, [inputProps.value]);


        const handleBlur = React.useCallback(
            (e) => {
                setValue(e.target.value.trim());
            }
        );

        const handleInput = React.useCallback(
            (e) => {
                setValue(e.target.value);
            },
        );

        const handleRequestSearch = React.useCallback(() => {
            if (value === undefined || value.trim() === '') return;
            localStorage.setItem('currentsearch', value);
            localStorage.removeItem('searchType');
            var iOSstr = encIOSURL(value);
            var encsearch = encodeURIComponent(iOSstr);
            var apiUrl = 'search?searchtype=2&searchterm=' + encsearch;

            dispatch(switchMasterMode('search'));
            dispatch(switchApiPage(1));
            dispatch(switchApiUrl(apiUrl));
            dispatch(fetchApiData());
            navigate('/search');
            scrollToTop();
        }, [value, dispatch, navigate]);

        const handleKeyUp = React.useCallback(
            (e) => {
                if (e.charCode === 13 || e.key === "Enter") {
                    handleRequestSearch();
                } else if (
                    cancelOnEscape &&
                    (e.charCode === 27 || e.key === "Escape")
                )
                    if (inputProps.onKeyUp) {
                        inputProps.onKeyUp(e);
                    }
            },
            [handleRequestSearch, inputProps, cancelOnEscape]
        );


        React.useImperativeHandle(ref, () => ({
            focus: () => {
                inputRef.current.focus();
            },
            blur: () => {
                inputRef.current.blur();
            },
        }));

        return (
            <>
                <TextField
                    {...inputProps}
                    inputRef={inputRef}
                    onBlur={handleBlur}
                    value={value}
                    onChange={handleInput}
                    onKeyUp={handleKeyUp}
                    fullWidth
                    disableUnderline
                    //style={{ backgroundColor: '#333' }}
                    label="Search for anything ..."
                    InputProps={{
                        endAdornment: (
                            <InputAdornment>
                                <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                                    <Button>{smallScreen ? !selectedIndex ? <FilterAltOff /> : <FilterAltIcon /> : !options[selectedIndex] ? options[0] : options[selectedIndex]}</Button>
                                    <Button
                                        size="small"
                                        aria-controls={open ? 'split-button-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-label="select merge strategy"
                                        aria-haspopup="menu"
                                        onClick={handleToggle}
                                    >
                                        <ArrowDropDownIcon />
                                    </Button>
                                </ButtonGroup>
                                <Popper
                                    style={{ zIndex: 2000 }}
                                    open={open}
                                    anchorEl={anchorRef.current}
                                    role={undefined}
                                    transition
                                    disablePortal
                                >
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin:
                                                    placement === 'bottom' ? 'center top' : 'center bottom',
                                            }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList id="split-button-menu">
                                                        {options.map((option, index) => {
                                                            return (
                                                                <MenuItem
                                                                    key={option}
                                                                    selected={index === selectedIndex}
                                                                    onClick={(event) => handleMenuItemClick(event, index)}
                                                                >
                                                                    {option}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                                <IconButton onClick={handleRequestSearch}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <div style={{ float: 'right', marginRight: 50, fontSize: '0.85em', color:'#ff6304'}}>{smallScreen && options[selectedIndex] !== undefined && "Applying Filter " + options[selectedIndex]}</div>
            </>
        );
    }
);

SearchField.propTypes = {
    switchMasterMode: PropTypes.func.isRequired,
    switchApiUrl: PropTypes.func.isRequired,
    switchApiPage: PropTypes.func.isRequired,
    fetchApiData: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    api: state.api,
    user: state.user
})

export default connect(mapStateToProps,
    {
        switchMasterMode,
        switchApiUrl,
        switchApiPage,
        fetchApiData
    })(SearchField);