import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import ShowBoxTable from './TrackTables/ShowBoxTable';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

const Category = (props) => {

    const collectionId = (new URLSearchParams(window.location.search)).get("collectionId");

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };

    useEffect(() => {

        scrollToTop();

    }, []);

    return (
        <Container className="ft-container" maxWidth="md">
            <CssBaseline />
            <Grid className="search-container" container spacing={2}>
                <Grid item xs={12}>
                    <ShowBoxTable scrollToTop={() =>scrollToTop()} collectionID={collectionId}  />
                </Grid>
            </Grid>

        </Container>
    );

}

export default (Category);
