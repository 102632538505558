import * as React from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { addToPlaylist, loadLocalPlaylist } from '../../../../FlikTraxStore/Actions/userActions';
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';

import Tooltip from '@mui/material/Tooltip';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

const AddToPlaylist = (props) => {

    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const userAuthenticated = useSelector((state) => state.user && state.user.isAuthenticated);

    const Notify = (notification) => {
        enqueueSnackbar(notification, { variant: "success" });
    }

    const pushToPlaylist = () => {

        if (!userAuthenticated) {
            navigate('/login');
        }else{
            dispatch(addToPlaylist(props.trackID));
            let msg = props.trackTitle + " added to playlist";
            Notify(msg);
            dispatch(loadLocalPlaylist());
            props.handleMobileMenuClose();
        }
        
    };


    return (
        <>
            <React.Fragment>
                <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    title="Add To My Playlist"
                    placement="top"
                    arrow
                >
                    <IconButton onClick={() => pushToPlaylist()} sx={{ padding: 1 }} size="small" aria-label="Add To Playlist" color="success">
                     <PlaylistAddIcon />
                    </IconButton>
                </Tooltip>
            </React.Fragment>
        </>


    );
}

function mapStateToProps(state) {
    return {
        api: state.api,
        user: state.user
    };
}

export default connect(mapStateToProps, {
    addToPlaylist,
    loadLocalPlaylist
})(AddToPlaylist);