import React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

export default function BrandLogos() {

  const brandlogos = ["shane.png",
    "10-things.jpg",
    "4nxmvnrw.png",
    "60-days-ini_.jpg",
    "an1o_ngg.png",
    "a-tate-of-history.jpg",
    "atnugu4a.png",
    "autopsy.jpg",
    "bbc.jpg",
    "best-thing-_.jpg",
    "bm01ywqa.png",
    "cakealikes.jpg",
    "cbs-sports.jpg",
    "coy5m0uq.png",
    "deadly-recall.jpg",
    "extinct-or-alive.jpg",
    "flipping-out-bravo-tv-show.jpg",
    "igcbltug.png",
    "itv-this-morning.jpg",
    "iyizi8aw.png",
    "jklh44vw.png",
    "khkoawwg.png",
    "killer-couples.jpg",
    "kpio-_pq.png",
    "listing-impossible.jpg",
    "marriage-or-mortgage.jpg",
    "return-to-amish.jpg",
    "sc5mck9w.png",
    "snapped.jpg",
    "the_dengineers_title_card.jpg",
    "the-donkey-king.jpg",
    "tllpmcjq.png",
    "toddlers-and-tiaras-where-are-they-now-1611166212778.jpg",
    "tphpvida.png",
    "trading-spaces.jpg",
    "xbgi9eta.png",
    "xgngj9eg.png",
    "xnzqhnkw.png"];

  return (
    <Box>
      <ImageList variant="masonry" cols={3} gap={8}>
        {brandlogos.map((logo, index) => {
          let currentlogo = '/images/logos/brand-logos/fliktrax/' + logo;
          return (
            <ImageListItem key={index}>
              <img
                src={currentlogo}
                srcSet={currentlogo}
                alt=""
                loading="lazy"
              />
            </ImageListItem>
          )
        })}
      </ImageList>
    </Box>
  );
}