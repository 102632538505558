import * as React from 'react';
import SavePlaylistDialog from './PlaylistActions/SavePlaylistDialog';
import ClearlaylistDialog from './PlaylistActions/ClearPlaylistDialog';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export default function PositionedMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title="Playlist Actions"
                placement="top"
                arrow
            >
            <IconButton
                color="inherit"
                aria-label="delete"
                id="save-playlist-button"
                aria-controls="save-playlist-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MenuOpenRoundedIcon style={{ fontSize: 35 }} />
            </IconButton>
            </Tooltip>

            <Menu
                id="save-playlist-menu"
                aria-labelledby="save-playlist-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'left',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'left',
                    horizontal: 'right',
                }}
            >
                <List>
                    <SavePlaylistDialog />
                    <ClearlaylistDialog />
                    {/* <ListItem disablePadding>
                        <ListItemButton onClick={handleClose}>
                            <ListItemIcon>
                                <ShareOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Share Playlist" />
                        </ListItemButton>
                    </ListItem> */}
                </List>
            </Menu>
        </>
    );
}
