import {
    UPDATE_NOW_PLAYING,
    UPDATE_FEATURED_PLAYING,
    UPDATE_IS_PLAYING,
    UPDATE_IS_PAUSED,
    UPDATE_POSITION,
    UPDATE_PLAYER_STATUS,
    UPDATE_AUTOPLAY,
    UPDATE_FEATURED_AUTOPLAY,
    TOGGLE_AUTOPLAY,
    TOGGLE_WAVEPLAYER
} from '../Actions/types';

const initialState = {
    currentTrack: null,
    currentTrackTitle: '',
    currentArtist: '',
    currentDuration: '',
    autoplay: true,
    waveautohide: true,
    autoplaytracks: [],
    featuredautoplaytracks: [],
    isPlaying: false,
    isPaused: false,
    featuredPlaying: false,
    playerStatus: 'stopped',
    position: 0
}

const audioPlayerReducers = (state = initialState, action) => {


    switch (action.type) {
        case UPDATE_NOW_PLAYING:
            return {
                ...state,
                currentTrack: action.payload.source,
                currentTrackTitle: action.payload.tracktitle,
                currentArtist: action.payload.artist,
                currentDuration: action.payload.duration,
                currentTrackId: action.payload.trackid
            }

        case UPDATE_IS_PLAYING:
            return {
                ...state,
                isPlaying: action.payload
            }

        case UPDATE_IS_PAUSED:
            return {
                ...state,
                isPaused: action.payload
            }            

        case UPDATE_FEATURED_PLAYING:
                return {
                    ...state,
                    featuredPlaying: action.payload
                }     

        case UPDATE_PLAYER_STATUS:
            return {
                ...state,
                playerStatus: action.payload
            }

        case UPDATE_POSITION:
            return {
                ...state,
                position: action.payload
            }

        case UPDATE_AUTOPLAY:
            return {
                ...state,
                autoplaytracks: action.payload
            }

        case UPDATE_FEATURED_AUTOPLAY:
            return {
                ...state,
                featuredautoplaytracks: action.payload
            }    

        case TOGGLE_AUTOPLAY:
            return {
                ...state,
                autoplay: action.payload
            }

        case TOGGLE_WAVEPLAYER:
            return {
                ...state,
                waveautohide: action.payload
            }

        default:
            return state;
    }
}

export default audioPlayerReducers;