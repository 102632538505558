import React, { useEffect, useState } from 'react';
import axios from 'axios';
import apiconfig from '../../config/api-config.js';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import ShowBoxCard from './TrackTables/ShowBoxComponents/ShowBoxCard';

const ShowBoxes = (props) => {

    const [collections, setCollections] = useState(null);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {

        scrollToTop();

        if (!collections) {
            let axiosUrl = 'https://api.fliktrax.com/newreleases?category=reelsync-music&playlist=[]';
            axios({
                baseURL: axiosUrl,
                method: 'get',
                auth: {
                    username: apiconfig.api.APIUSERNAME,
                    password: apiconfig.api.APIPASSWORD
                },
                headers: {
                    "Content-Type": "application/hal+json"
                },
                //timeout: 2000,
            })
                .then(response => {
                    setCollections(response.data._embedded.newreleases);
                })
        }

    }, [collections]);

    return (
        <Container className="ft-container" maxWidth="md">
            <h1  style={{color:"#318df1"}}>Curated Playlists</h1>
            <Box style={{marginTop:50}}>
                <CssBaseline />
                <Grid container spacing={4}>
                    {collections && collections.map((data, i) => {
                        return (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <ShowBoxCard cardData={data} />
                                </Grid>
                            </>
                        )
                    })}
                </Grid>
            </Box>
            <div style={{marginTop:50}}></div> 
        </Container>
    )
}

export default ShowBoxes;