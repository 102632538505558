import * as React from 'react';
import { connect } from "react-redux";
import { updatePlaylistVisible, loadLocalPlaylist, updateAuthStatus, setAuthUser, updateAuthenticating, updatePlaylistCounts, smsNotify } from '../../FlikTraxStore/Actions/userActions';
import { apiIsLoading } from '../../FlikTraxStore/Actions/apiActions';
import MyMiniPlaylist from './MyMiniPlaylist';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faThList } from '@fortawesome/free-solid-svg-icons';
library.add(faThList);


const MiniPlaylistButton = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePlaylistClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    title="My Playlist"
                    placement="bottom"
                    arrow
                >
                    <IconButton size="large" aria-label="My Playlist" color="inherit">
                        <Badge badgeContent={props.user && props.user.userPlaylist && Object.keys(props.user.userPlaylist).length} color="secondary">
                            <FontAwesomeIcon icon={faThList} />
                        </Badge>
                    </IconButton>
                </Tooltip>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handlePlaylistClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                
                <MyMiniPlaylist handlePlaylistClose={() => handlePlaylistClose()} />
             
            </Menu>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user: state.user,
        api: state.api
    };
}

export default connect(mapStateToProps, {
    updateAuthStatus,
    setAuthUser,
    updateAuthenticating,
    apiIsLoading,
    updatePlaylistCounts,
    updatePlaylistVisible,
    loadLocalPlaylist,
    smsNotify
})(MiniPlaylistButton);
