import { combineReducers }  from 'redux';
import apiReducer from './apiReducer';
import filterReducer from './filterReducer';
import userReducer from './userReducer';
import audioPlayerReducer from './audioPlayerReducer';
import snackbarReducer from './snackbarReducer';

export default combineReducers({
    api: apiReducer,
    filters: filterReducer,
    user: userReducer,
    audioplayer: audioPlayerReducer,
    snackbar: snackbarReducer
})