const cognitoConfig = {
	apiGateway: {
		REGION: 'us-east-1',
		URL: 'YOUR_API_GATEWAY_URL'
	},
	cognito: {
		REGION: 'us-east-1',
		// IDENTITY_POOL_ID: 'us-east-1_gzhbkS3Kl',
		// USER_POOL_ID: 'us-east-1_gzhbkS3Kl',
		// APP_CLIENT_ID: '3h6k0maue11aqfa44k55u476bo'

		IDENTITY_POOL_ID: 'us-east-1_AnvWXjc1J',
		USER_POOL_ID: 'us-east-1_AnvWXjc1J',
		APP_CLIENT_ID: '9a7b42f3j0f6u85no3thupul9'

		// USER_POOL_ID: 'us-east-1_ZYRWpxyOA',
		// APP_CLIENT_ID: '1u4c8hvq0cp3k0dib83bllolf5'
		//IDENTITY_POOL_ID: 'us-east-1_gzhbkS3Kl' // Federated: us-east-1:65bd1e7d-546c-4f8c-b1bc-9e3e571cfaa7
	}
};

/*
// Initialize the Amazon Cognito credentials provider
CognitoCachingCredentialsProvider credentialsProvider = new CognitoCachingCredentialsProvider(
    getApplicationContext(),
    "us-east-1:d79d4b42-bcd9-4b46-9da8-bef3edac9824", // Identity pool ID
    Regions.US_EAST_1 // Region
);

*/

export default cognitoConfig;