import React  from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import TablePagination from '@mui/material/Pagination';
import { fetchApiData, switchApiPage } from '../../../FlikTraxStore/Actions/apiActions';

const Paginator = (props) => {

  const dispatch = useDispatch();

  const apiData = useSelector((state) => state.api && state.api.apiData);
  const dataIsLoading = useSelector((state) => state.api && state.api.isLoading);
  const [page, setPage] = React.useState(apiData && apiData.page);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(switchApiPage(newPage));
    dispatch(fetchApiData());
    document.body.scrollTop = 375;
    document.documentElement.scrollTop = 375;
  };

  if (!dataIsLoading) {
    return (
      <TablePagination
        sx={{ marginBottom: '1rem', marginTop: '1rem' }}
        size="medium"
        variant="outlined"
        color="secondary"
        defaultPage={1}
        shape="rounded"
        siblingCount={3}
        boundaryCount={1}
        style={{ float: 'right' }}
        showFirstButton={apiData.page > 2 ? true: false}
        showLastButton={apiData.page < apiData.page_count ? true: false}
        colSpan={7}
        count={apiData && apiData.page_count}
        page={apiData && apiData.page}
        onChange={handleChangePage}
      />

    );
  } else {
    return null;
  }
}

Paginator.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default (Paginator);