import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { deleteSavedPlaylist , loadSavedPlaylistCount, fetchDefaultPlaylist, loadLocalPlaylist  } from '../../../../FlikTraxStore/Actions/userActions';
import { useSnackbar } from 'notistack';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompactDisc } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Navigate } from 'react-router';
library.add(faCompactDisc);



const DeleteSavedPlaylistDialog = (props) => {

    const [open, setOpen] = React.useState(false);

    const [submitting, setSubmitting] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const dataIsLoading = useSelector((state) => state.api && state.api.isLoading);

    const dispatch = useDispatch();


    const { enqueueSnackbar } = useSnackbar();

    const Notify = (notification, type = 'success') => {
        enqueueSnackbar(notification, { variant: type });
    }

    const navigate = useNavigate();

    const removePlaylist = () => {

        console.log(props.playlistName)

        setSubmitting(true);
        dispatch(deleteSavedPlaylist(props.playlistID));
        setTimeout(() => {
            dispatch(loadLocalPlaylist()); 
            dispatch(loadSavedPlaylistCount());
            setOpen(false);
            Notify("Playlist " + props.playlistName +"  successfully", 'success');
            window.location.reload();
        }, 1500);
    }

    return (
        <div>
            <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title="Delete This Playlist"
                placement="top"
                arrow
            >
                <Button size="small" color="error"><DeleteForeverOutlinedIcon onClick={handleClickOpen} /></Button>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {/* <DeleteSweepOutlinedIcon style={{ marginRight: 5, marginTop: 5 }} /> Clear Playlist */}
                    {/* <img style={{ maxHeight: 40, marginTop: 10, float: 'right' }} src="/images/logos/reelsync-logo-bw.jpg" alt="TVPros" /> */}
                    <Typography variant="h5" component="div">
                        <DeleteForeverOutlinedIcon size="lg" color="warning" style={{ marginRight: 5, marginTop: 5 }} /> Delete Saved Playlist
                        <img style={{ maxHeight: 50, marginTop: 10, float: 'right' }} src="/images/logos/reelsync-logo-bw.jpg" alt="TVPros" />
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} p={3}>
                        <Grid item xs={12} p={4}>
                            <Typography style={{ textAlign: 'center' }} variant="subtitle1" color="warning" component="div">
                                Are you sure you want to clear your playlist?<br />This action cannot be undone.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <Button
                                variant="primary"
                                fullWidth
                                size="large"
                                color="warning"
                                bgcolor="warning"
                                disabled={submitting}
                                onClick={removePlaylist}
                                type="submit"
                            >
                                Clear Playlist{submitting && (<FontAwesomeIcon style={{ marginLeft: 10 }} icon={faCompactDisc} size="2x" spin />)}
                            </Button>

                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>
        </div>
    );
}

DeleteSavedPlaylistDialog.propTypes = {
    clearDefaultPlaylist: PropTypes.func.isRequired,
    loadSavedPlaylistCount: PropTypes.func.isRequired,
    fetchDefaultPlaylist: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    api: state.api,
    user: state.user,
})

export default connect(mapStateToProps,
    {
        deleteSavedPlaylist,
        loadSavedPlaylistCount,
        fetchDefaultPlaylist
    })(DeleteSavedPlaylistDialog);

