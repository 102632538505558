import React, { useEffect } from 'react';
import { updateAuthStatus, setAuthUser, updateAuthenticating, updatePlaylistCounts, smsNotify } from '../../../FlikTraxStore/Actions/userActions';
import { apiIsLoading } from '../../../FlikTraxStore/Actions/apiActions';
import { connect } from 'react-redux';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import TrackInfo from './TrackActionButtons/TrackInfo';
import Download from './TrackActionButtons/Download';
import RemoveFromPlaylist from './TrackActionButtons/RemoveFromPlaylist';
import MoreLikeFavorites from './TrackActionButtons/MoreLikeFavorites';

function SharedTrackActions(props) {


    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <MoreLikeFavorites trackParent={props.row} />
            </MenuItem>
            <MenuItem>
                <TrackInfo {...props} trackParent={props.row} trackID={props.isMix === true ? props.parentID : props.trackID} handleMobileMenuClose={() => handleMobileMenuClose()} />
            </MenuItem>

            <MenuItem>
                <Download {...props} />
            </MenuItem>

        </Menu>
    );

    return (

        <React.Fragment>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

                <MoreLikeFavorites trackParent={props.row} />

                <TrackInfo {...props}
                    trackParent={props.row}
                    trackID={props.isMix === true ? props.parentID : props.trackID}
                    handleMobileMenuClose={() => handleMobileMenuClose()}
                />

                <Download {...props} />

            </Box>

            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                    style={{ marginRight: 75 }}
                    size="small"
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                >
                    <MoreIcon />
                </IconButton>
            </Box>
            {renderMobileMenu}

        </React.Fragment>
    );
}



function mapStateToProps(state) {
    return {
        user: state.user,
        api: state.api,
    };
}

export default connect(mapStateToProps, {
    updateAuthStatus,
    setAuthUser,
    updateAuthenticating,
    apiIsLoading,
    updatePlaylistCounts,
    smsNotify,
})(SharedTrackActions);