import * as React from 'react';
import { useLocation } from "react-router-dom";
import Login from './LoginRegister/Login';
import Register from './LoginRegister/Register';
import ShowBoxes from './Dashboards/ShowBoxes';
import ShowBox from './Dashboards/ShowBox';
import Categories from './Dashboards/Categories';
import Category from './Dashboards/Category';
import Collections from './Dashboards/Collections';
import Collection from './Dashboards/Collection';
import Search from './Dashboards/Search';
import MyPlaylist from './Dashboards/MyPlaylist';
import SharedPlaylist from './Dashboards/SharedPlaylist';
import SavedPlaylists from './Dashboards/SavedPlaylists';
import About from './Company/About';
import CustomMusic from './Company/CustomMusic';
import Request from './Company/Request';
import Support from './Company/Support';
import NotFound from './NotFound';


export default function MasterPortal() {

    const { pathname } = useLocation();

    switch (pathname) {

        case '/':
            return <ShowBoxes />;

        case '/login':
            return <Login />;

        case '/register':
            return <Register />;

        case '/about':
            return <About />;

        case '/custom-music':
            return <CustomMusic />;

        case '/request':
            return <Request />;

        case '/support':
            return <Support />;

        case '/showbox':
            return <ShowBox/>;    

        case '/categories':
            return <Categories />;

        case '/category':
            return <Category />;

        case '/collection':
            return <Collection />;

        case '/collections':
            return <Collections />;

        case '/search':
            return <Search />;

        case '/my-playlist':
            return <MyPlaylist />;

        case '/saved-playlists':
            return <SavedPlaylists />;

        case '/shared-playlist':
            return <SharedPlaylist />;

        default:
            return <NotFound />;
    }
}