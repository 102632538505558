import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";

import {
    removeFromPlaylist,
    clearDefaultPlaylist,
    saveDefaultPlaylist,
    updatePlaylistFromLocal,
    loadLocalPlaylist
} from '../../../../FlikTraxStore/Actions/userActions';

import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';

import Tooltip from '@mui/material/Tooltip';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';

const TrackInfo = (props) => {

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const Notify = (notification) => {
        enqueueSnackbar(notification, { variant: "success" });
    }

    const popFromPlaylist = () => {
        dispatch(removeFromPlaylist(props.trackID));
        let msg = props.trackTitle + " removed from playlist";
        Notify(msg);
        document.getElementById('plRow-' + props.trackID).style.visibility = "hidden";
        dispatch(loadLocalPlaylist());
        props.handleMobileMenuClose();
    };

    return (
            <React.Fragment>
                <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    title="Remove From Playlist"
                    placement="top"
                    arrow
                >
                    <IconButton onClick={() => popFromPlaylist()}
                        sx={{ padding: 1 }}
                        size="small"
                        aria-label="Add To Playlist"
                        color="secondary"
                    >
                        <PlaylistRemoveIcon />
                    </IconButton>
                </Tooltip>
            </React.Fragment>
    );
}

function mapStateToProps(state) {
    return {
        api: state.api,
        user: state.user
    };
}

export default connect(mapStateToProps, {
    removeFromPlaylist,
    clearDefaultPlaylist,
    saveDefaultPlaylist,
    updatePlaylistFromLocal,
    loadLocalPlaylist
})(TrackInfo);