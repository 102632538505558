import * as React from 'react';
import { styled } from '@mui/material/styles';
import { titleCase } from "title-case";
import DeleteSavedPlaylistDialog from './SavedPlaylistActions/DeleteSavedPlaylistDialog';
import ShareSavedPlaylistDialog from './SavedPlaylistActions/ShareSavedPlaylistDialog';
import ButtonGroup from '@mui/material/ButtonGroup';
import PlayPause from '../ActionComponents/PlayPause';
import TrackActions from '../ActionComponents/TrackActions';
import Scroll from 'react-scroll-to-element';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Accordion = styled((props) => (

  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .03)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


function Row(props) {

  const { row } = props;

  return (

    <React.Fragment>
      <TableRow id={'plRow-' + props.trackID} hover sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell align="left">
          <PlayPause {...props} />
        </TableCell>
        <TableCell component="th" scope="row">
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} style={{ textAlign: 'left' }}>
              <Scroll type="id" element={props.trackID} offset={-75}>
                <Typography noWrap variant="h6" color="primary">
                  <span style={{ marginRight: 10 }}>{row.track_title}</span>
                </Typography>
              </Scroll>
              <Typography noWrap variant="subtitle1">
                {row.artist_name} ({row.duration})
              </Typography>

            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="left">
          <TrackActions {...props}
            playlistMode={false}
            trackTitle={row.track_title}
            trackParent={row}
            trackID={props.trackID}
            fileMp3={row.file_path_compressed}
            fileUncompressed={row.file_path_uncompressed}
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
const SavedPlaylistsAccordian = (props) => {

  const [expanded, setExpanded] = React.useState('');

  const [expandedPlaylist, setExpandedPlaylist] = React.useState('');

  const handleChange = (panel, plindex) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    if (newExpanded) {
      let currentPlaylist = props.savedPlaylists[plindex].playlist;
      setExpandedPlaylist(currentPlaylist);
    }
  };

  return (
    <>
      {props && props.savedPlaylists && props.savedPlaylists.map((playlist, index) => {

        let panelID = 'panel-' + playlist.id;
        let panelContentID = playlist.id + '-content';
        let panelHeaderID = playlist.id + '-header';

        if (playlist.id) {
          return (
            <Accordion expanded={expanded === panelID} onChange={handleChange(panelID, index)} TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary aria-controls={panelContentID} id={panelHeaderID}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography style={{ whiteSpace: 'nowrap', marginTop: 10 }} variant='h5' color="primary" nowrap>{titleCase(playlist.title)}</Typography>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>

                  </Grid>

                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer sx={{ padding: 2 }} component={Paper} >
                  <Table aria-label="Latest Tracks">
                    <TableBody>


                      <>
                        <TableRow id={'plRow-' + props.trackID} hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                          <TableCell colspan={3} align="right">
                            <ButtonGroup size="small" style={{ marginLeft: '20%' }} variant="outlined" aria-label="outlined secondary button group">
                              <ShareSavedPlaylistDialog playlistID={playlist.id} playlistName={titleCase(playlist.title)} />
                              <DeleteSavedPlaylistDialog playlistID={playlist.id} playlistName={titleCase(playlist.title)} />
                            </ButtonGroup>
                          </TableCell>
                        </TableRow>
                      </>


                      {expandedPlaylist && expandedPlaylist.map((data) => {
                        let dataAttrs = {
                          'data-source': data.preview_url,
                          'data-tracktitle': data.track_title,
                          'data-artist': data.artist_name,
                          'data-description': data.description,
                          'data-duration': data.duration,
                          'data-vocaltype': data.vocal_type,
                          'data-trackid': data.id,
                          'data-filecompressed': data.file_path_compressed,
                          'data-fileuncompressed': data.file_path_uncompressed
                        };
                        return (
                          <Row key={data.id} row={data} dataAttr={dataAttrs} trackID={data.id} />
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          )
        }
      })}
    </>
  );

}

export default SavedPlaylistsAccordian;