import React, { useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { Link, useNavigate } from "react-router-dom";
import { updatePlaylistVisible, loadLocalPlaylist, updateAuthStatus, setAuthUser, updateAuthenticating, updatePlaylistCounts, smsNotify } from '../../FlikTraxStore/Actions/userActions';
import { apiIsLoading } from '../../FlikTraxStore/Actions/apiActions';
import MyAccount from './MyAccount/MyAccount';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import MiniPlaylistButton from './MiniPlaylistButton';
import LoadingBackdrop from '../../Utilities/LoadingBackdrop';
import Typography from '@mui/material/Typography';
// import SwitchTheme from './SwitchTheme';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import MoreIcon from '@mui/icons-material/MoreVert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCog, faThList, faStream, faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

library.add(faCog, faThList, faStream, faUser);

function PlaylistIcons(props) {

    const navigate = useNavigate();

    const userAttributes = useSelector((state) => state.user && state.user.user && state.user.user.attributes);

    const [bgopen, setBgOpen] = React.useState(false);

    async function handleLogOut() {
        setBgOpen(true);
        await Auth.signOut();
        window.location.replace("/login");
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom'
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <List>
                <MyAccount handleMenuClose={() => handleMenuClose()}/>
                <Divider />
                <ListItem disablePadding>
                    <ListItemButton onClick={() => handleLogOut()}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faSignOutAlt} />
                        </ListItemIcon>
                        <ListItemText primary="Log Out" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Menu >
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MiniPlaylistButton />
            <MenuItem>
                <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    title="Saved Playlists"
                    placement="left"
                    arrow
                >
                    <IconButton
                        onClick={() => navigate('/saved-playlists')}
                        size="large"
                        aria-label="Saved Playlists"
                        color="inherit"
                    >
                        <Badge badgeContent={props.user && props.user.savedPlaylistCount && props.user.savedPlaylistCount} color="secondary">
                            <FontAwesomeIcon icon={faStream} />
                        </Badge>
                    </IconButton>
                </Tooltip>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => handleLogOut()}>
                <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    title="Log Out"
                    placement="left"
                    arrow
                >
                    <IconButton
                        size="large"
                        aria-label="Log Out"
                        color="inherit"
                    >
                        <FontAwesomeIcon icon={faSignOutAlt} />

                    </IconButton>
                </Tooltip>
            </MenuItem>



            {/* <Divider />
            <SwitchTheme /> */}
        </Menu>
    );


    useEffect(() => {
        if (props.user && props.user.authenticated) {
            props.loadLocalPlaylist();
            const user = Auth.currentAuthenticatedUser();
            props.updatePlaylistCounts();
        }
    }, [props]);



    return (
        <React.Fragment>
            <CssBaseline />
            <LoadingBackdrop open={bgopen} bgtxt="... Logging out" />
            {props.user && props.user.isAuthenticated && (
                <React.Fragment>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Typography className="user-title" noWrap>
                            Welcome {props.user && props.user.user && userAttributes['custom:firstname']}!
                        </Typography>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <MiniPlaylistButton />
                        <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title="Saved Playlists"
                            placement="bottom"
                            arrow
                        >
                            <IconButton
                                onClick={() => navigate('/saved-playlists')}
                                size="large"
                                aria-label="show 17 new notifications"
                                color="inherit"
                            >
                                <Badge badgeContent={props.user && props.user.savedPlaylistCount && props.user.savedPlaylistCount} color="secondary">
                                    <FontAwesomeIcon icon={faStream} />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <FontAwesomeIcon icon={faCog} />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                    {renderMobileMenu}
                    {renderMenu}
                </React.Fragment>
            )}
            {props.user && !props.user.isAuthenticated && (
                <>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Stack spacing={2} direction="row">
                            <Link to="/login"><Button color="secondary" variant="outlined">Login</Button></Link>
                            <Link to="/register"><Button color="secondary" variant="outlined">Register</Button></Link>
                        </Stack>
                    </Box>
                </>
            )}
        </React.Fragment>
    );
}


PlaylistIcons.propTypes = {
    updateAuthStatus: PropTypes.func.isRequired,
    setAuthUser: PropTypes.func.isRequired,
    updateAuthenticating: PropTypes.func.isRequired,
    smsNotify: PropTypes.func.isRequired,
    apiIsLoading: PropTypes.func.isRequired,
    updatePlaylistCounts: PropTypes.func.isRequired,
    loadLocalPlaylist: PropTypes.func.isRequired,
    updatePlaylistVisible: PropTypes.func.isRequired,
}


function mapStateToProps(state) {
    return {
        user: state.user,
        api: state.api
    };
}

export default connect(mapStateToProps, {
    updateAuthStatus,
    setAuthUser,
    updateAuthenticating,
    apiIsLoading,
    updatePlaylistCounts,
    updatePlaylistVisible,
    loadLocalPlaylist,
    smsNotify
})(PlaylistIcons);