import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSitemap } from '@fortawesome/free-solid-svg-icons';

library.add(faSitemap)

export default function CategoryBreadcrumbs(props) {
    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            pt={3}
            separator={<NavigateNextIcon fontSize="small" />}
        >
            <Link style={{ textDecoration: 'none' }} color="inherit" href="/categories">
            <FontAwesomeIcon style={{ color: 'white', marginRight: 5 }} icon={faSitemap} /> Categories
            </Link>
            <Typography underline="hover" color="text.primary">{props.categoryTitle}</Typography>
        </Breadcrumbs>
    );
}