import { FETCH_FILTER_DATA } from '../Actions/types';

const initialState = {
    moodsData: {},
    stylesData: {},
    instrumentsData: {},
    apiPage: 1,
    embeddedData: []
}

export default function(state = initialState, action){
    switch(action.type){

        case FETCH_FILTER_DATA:
            var slot = [action.filterSlot];
            var filter = [action.filterName];
            var embData = Object.values(action.payload._embedded);
            return {
                ...state,
                [slot]: embData
            }         
            
        default:
            return state;
    }
}